import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface PurchaseRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const QuotationsAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
    const location = useLocation();
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "analytics-rfq":
                return (
                    <Link to="/analytics-rfq" className={isActive(`${process.env.PUBLIC_URL}/analytics-rfq`)}>
                        <Icon name="growth-fill" />

                        <span>Analytics</span>
                    </Link>
                );
            case "new-rfq":
                return (
                    <Link to="/new-rfq" className={isActive(`${process.env.PUBLIC_URL}/new-rfq`)}>
                        <Icon name="plus-fill-c" />
                        <span>Create RFQ</span>
                    </Link>
                );
            case "rfq-approvals":
                return (
                    <Link to="/rfq-approvals" className={isActive(`${process.env.PUBLIC_URL}/rfq-approvals`)}>
                        <Icon name="check-fill-c" />
                        <span>Pending Approvals RFQ</span>
                    </Link>
                );
            case "view-rfqs":
                return (
                    <Link to="/view-rfqs" className={isActive(`${process.env.PUBLIC_URL}/view-rfqs`)}>
                        <Icon name="eye-fill" />
                        <span>View RFQ</span>
                    </Link>
                );
            case "qc-approvals":
                return (
                    <Link to="/qc-approvals" className={isActive(`${process.env.PUBLIC_URL}/qc-approvals`)}>
                        <Icon name="check-round-cut" />
                        <span>Pending Approvals QC</span>
                    </Link>
                );
            case "ready-to-award":
                return (
                    <Link to="/ready-to-award" className={isActive(`${process.env.PUBLIC_URL}/ready-to-award`)}>
                        <Icon name="award" />
                        <span>Ready to Award</span>
                    </Link>
                );
            // Add more cases if needed for other routes
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>Quotations</h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "analytics-rfq",
                        "new-rfq",
                        "rfq-approvals",
                        "view-rfqs",
                        "qc-approvals",
                        "ready-to-award"
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default QuotationsAside;