import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Spinner, Alert } from "reactstrap";
import Cookies from "js-cookie";

import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { LoginCredentials } from "../../api/types/auth/authTypes";
// import { login } from "../../api/auth/authService";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { fetchCurrentUser, logout } from "../../store/slices/misc/currentUser";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";
import authService from "../../api/auth/authService";


const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch:any=useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [passState, setPassState] = useState<boolean>(false);
  const [errorVal, setError] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>();

  const onFormSubmit = async (formData: LoginCredentials) => {
    setLoading(true);
    try {
      dispatch(logout());
      const data = await authService.login({...formData,applicationType:"Buyer"});
      if (data.status && data.token && data.token.access_token) {
        Cookies.set('access-token', JSON.stringify(data.token), { expires: 7, secure: true, sameSite: 'Strict' });
        Cookies.set('refresh-token', data.token.refresh_token, { expires: 7, secure: true, sameSite: 'Strict' });
        dispatch(fetchCurrentUser()); 
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
        setLoading(false);
      } else {
        setError(data.message);
        setLoading(false);
      }
    } catch (err: any) {
      console.log('Login failed:', err.message);
      setError(err.message);
      setLoading(false);
  }
}
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  console.log("userAgent",userAgent);
  // alert(JSON.stringify(userAgent));
  const browserMapper = {
    'Edg': 'Microsoft Edge',
    'OPR': 'Opera',
    'Vivaldi': 'Vivaldi',
    'SamsungBrowser': 'Samsung Internet',
    'QQBrowser': 'QQ Browser',
    'UCBrowser': 'UC Browser',
    'YaBrowser': 'Yandex Browser',
    'Silk': 'Amazon Silk',
    'Maxthon': 'Maxthon',
    'Brave': 'Brave',
    'Chrome': 'Chrome',
    'Safari': 'Safari',
    'Firefox': 'Firefox',
    'MSIE': 'Internet Explorer',
    'Trident/': 'Internet Explorer',
    'IEMobile': 'Internet Explorer',
    'Opera': 'Opera',
    'Netscape': 'Netscape',
    'SeaMonkey': 'SeaMonkey',
    'Konqueror': 'Konqueror',
    'Firefox Focus': 'Firefox Focus',
    'Iceweasel': 'Firefox',
  };

  // Default to unknown
  let browserName = "Unknown";

  // Check each key in the mapper object
  for (const key in browserMapper) {
    if (userAgent.indexOf(key) > -1) {
      browserName = browserMapper[key as keyof typeof browserMapper];
      break;
    }
  }
// alert(browserName);
  return browserName;
}

const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address: ", error);
  }
}

  useEffect(() => {
    // const accessToken = Cookies.get('accessToken');
    // if (accessToken) {
    //   window.location.href = `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`;
    // }
  }, []);

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="" src={LogoDark2x} alt="logo" />
            {/* <img className="" src={LogoDark} alt="logo-dark" /> */}
          </Link>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Sign-In</BlockTitle>
              <BlockDes>
                <p>Access Procurex using your email and password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> Unable to login with credentials
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Email Address
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register("email", { required: "This field is required" })}
                  placeholder="Enter your email address"
                  className="form-control-lg form-control"
                />
                {errors.email && (
                  <span className="invalid">{errors.email.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    passState ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("password", {
                    required: "This field is required",
                  })}
                  placeholder="Enter your passcode"
                  className={`form-control-lg form-control ${
                    passState ? "is-hidden" : "is-shown"
                  }`}
                />
                {errors.password && (
                  <span className="invalid">{errors.password.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
              >
                {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
              </Button>
            </div>
          </Form>
          <div className="form-note-s2 text-center pt-4">
            Forgot Password?{" "}
            <Link
              className="link link-primary"
              to={`${process.env.PUBLIC_URL}/auth-reset`}
            >
              Click here to reset
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};

export default Login;
