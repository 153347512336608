import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import { Divider, Popover, Space } from "antd";
import { EditOutlined } from '@ant-design/icons';
import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
import AskDocuments from "./AskDocuments";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import sites from "../../../api/master/sites";
import costCenterApis from "../../../api/master/costCenter";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import { AddressDetails, SelectOptions, ApprovalData, DocumentFormValues } from './types'; // Import your types
import usersApis from "../../../api/master/users";
import { useDispatch, useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import DeliveryAddressMaster from "../../masters/delivery-address/DeliveryAddressMaster";

const RFQGeneralDetails: React.FC = (props: any) => {
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>([]);
  const [billingAddressList, setBillingAddressList] = useState<AddressDetails[]>([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<SelectOptions[]>([]);
  const AllFormDetails = watch();
  const [prefferedStates, setPrefferedStates] = useState<any>([]);
  const siteIdWatch = watch("siteId");
  const approvalRequired = watch("approvalRequired");
  const supplierDocsRequired = watch("supplierDocsRequired");
  const needByDateType = watch("needByDateType");
  const deliveryAddressId = watch("deliveryAddressId");
  const billingAddressId = watch("billingAddressId");
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [documentData, setDocumentData] = useState<any>();
  const [documentsPopoverVisible, setDocumentsPopoverVisible] = useState(true);
  const [isAskDocumentsDrawerOpen, setIsAskDocumentsDrawerOpen] = useState(false);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const submitForm = (data: any) => {
    console.log("documentData", documentData);
    setValue("approvalsData", JSON.stringify(approvals));
    if (approvalRequired == "true" && approvals?.data?.length === 0) {
      return;
    }
    if (supplierDocsRequired == "y" && documentData?.documents.length === 0) {
      return;
    } else {
      setValue("documents", documentData)
    }
    props?.next();
  };



  const fetchCurrentUserInfo = async () => {
    try {
      const res = await usersApis.getCurrentUser();

      if (res) {
        const { prefferedStates } = res;
        setPrefferedStates(prefferedStates?.map((item: any) => item?.stateID));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleDocumentsSubmit = (data: DocumentFormValues) => {
    console.log(data);
    setDocumentData(data);
    setDocumentsPopoverVisible(false);
    onAskDocumentsClose();
  };

  const showAskDocumentsDrawer = () => {
    setIsAskDocumentsDrawerOpen(true);
  };

  const onAskDocumentsClose = () => {
    setIsAskDocumentsDrawerOpen(false);
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser()
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
      <DeliveryAddressMaster
        formAction={type}
        id={id}
        toggleModelPopUp={() => {
          setIsOpen(false);
          fetchDeliveryAddress();
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };

  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item[matchKey] == id);

    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address.addressLine1} {address.addressLine2}
            </li>
            <li>
              {address.city},{address?.districtName}, {address?.stateName},
              {address?.countryName} - {address.pincode}
            </li>
          </ul>
        }
        title="Selected Address"
      >
        <a>{text}</a>
      </Popover>
    );
  };


  useEffect(() => {
    fetchSites();
    fetchCostCenter();
    fetchDeliveryAddress();
    const {
      approvalsData,
      deliveryAddressType,
      needByDateType,
      autoRelease,
      quotationFor,
      supplierDocsRequired,
      documents
    } = AllFormDetails;
    console.log("documents", documents);
    if (documents) {
      setDocumentData(documents);
    }
    if (approvalsData) {
      setApprovals(JSON.parse(approvalsData));
    }
    if (autoRelease) {
      setValue("autoRelease", autoRelease);
    } else {
      setValue("autoRelease", "Y");
    }
    if (needByDateType) {
      setValue("needByDateType", needByDateType);
    } else {
      setValue("needByDateType", "ORDER_LEVEL");
    }
    if (deliveryAddressType) {
      setValue("deliveryAddressType", deliveryAddressType);
    } else {
      setValue("deliveryAddressType", "ORDER_LEVEL");
    }
    if (supplierDocsRequired === "Y") {
      setDocumentsPopoverVisible(false)
    }

    if (quotationFor) {
      setValue("quotationFor", quotationFor);
    } else {
      setValue("quotationFor", "Material");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);


  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {!sequenceAccess?.includes("Quotation") ? (
            <Col lg="4">
              <FormInput
                name="rfqId"
                label={`Quotation Number`}
                required={true}
                placeholder="Enter Quotation Number"
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg="4">
            <FormSelect
              name="quotationFor"
              label="Quotation For"
              defaultValue={{
                label: "Material",
                value: "Material",
              }}
              options={[{ label: 'Material', value: 'Material' }, { label: 'Service', value: 'Service' }]}
              required={true}
              placeholder="Select Quotation Type"
            />
          </Col>
          <Col lg="8">
            <FormInput
              name="quotationDescription"
              label="Quotation Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={100}
              placeholder="Enter Description"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="costCenter"
              label="Cost Center"
              options={costCenterOptions}
              required={false}
              placeholder="Select Cost Center"
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name='lastSubmissionDate'
              label="Last Submission Date"
              required={true}
              placeholder="Select Date"
              minDate={new Date()}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="deadlineType"
              label="Submission Type"
              options={[{ label: 'Soft', value: 'SOFT' }, { label: 'Hard', value: 'HARD' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="siteId"
              label="Site Name"
              options={sitesOptions}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "Y" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col>

          <Col lg="4">
            <FormSelect
              name="additionalCharges"
              label="Additional Charges?"
              options={[{ label: 'Fixed', value: 'Fixed' }, { label: 'Flexible', value: 'Flexible' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="autoRelease"
              label="Auto Release"
              defaultValue={{
                label: "Yes",
                value: "Y",
              }}
              options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="supplierDocsRequired"
              label="Require Documents from supplier?"
              options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
              required={true}
              placeholder="Select"
            // onChange={(val) => setValue(showAskDocs: val === 'Y' })}
            />
            {watch("supplierDocsRequired") == "Y" && (
              <Popover
                content="Click here to ask required documents"
                title="Documents Required"
              >
                <a href='#' className="success" onClick={(e) => {
                  e.preventDefault();
                  showAskDocumentsDrawer();
                }}>
                  <EditOutlined /> Ask Required Documents &nbsp;
                  {
                    documentsPopoverVisible ? <span className="mandatory">Please set documents</span> : <></>
                  }
                </a>
              </Popover>
            )}
          </Col>

          <Col lg="4">
            <FormSelect
              name="billingAddressId"
              label={`Billing Address${deliveryAddressOptions.length == 0
                ? "( Empty Billing Address List)"
                : ""
                }`}
              options={deliveryAddressOptions}
              popOverOptions={
                <ShowAddressPopOver
                  id={billingAddressId}
                  matchKey="id"
                  options={deliveryAddressList}
                  text="View Selected Billing Address"
                />
              }
              required={true}
              placeholder="-Select-"
              tooltipText="Select the billing address for the purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="deliveryAddressType"
              label="Delivery Address Type"
              options={[{ label: 'Order Level', value: 'ORDER_LEVEL' }, { label: 'Line Level', value: 'LINE_LEVEL' }]}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the delivery address type for the purchase order."
            />
          </Col>
          {watch("deliveryAddressType") === 'ORDER_LEVEL' && (
            <Col lg="4">
              <FormSelect
                name="deliveryAddressId"
                label="Delivery Address"
                options={deliveryAddressOptions}
                required={true}
                placeholder="-Select-"
                tooltipText="Select the delivery address for the purchase order."
                popOverOptions={
                  <ShowAddressPopOver
                    id={deliveryAddressId}
                    matchKey="id"
                    options={deliveryAddressList}
                    text="View Selected Delivery Address"
                  />
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleModelPopUp(null, "");
                        }}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <span>Add Address</span>
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Col>
          )}
          <Col lg="4">
            <FormSelect
              name="needByDateType"
              label="Multiple Delivery Dates ?"
              options={[
                { label: "Yes", value: "LINE_LEVEL" },
                { label: "No", value: "ORDER_LEVEL" },
              ]}
              placeholder="-Select-"
              tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
              required={true}
            />
          </Col>

          {needByDateType === "ORDER_LEVEL" && (

            <Col lg="4">
              <FormDatePicker
                name='needByDate'
                label="Need By Date"
                required={true}
                placeholder="Select Date"
                minDate={new Date()}
              />
            </Col>

          )}
        </Row>

        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
      {approvals.open && (
        <QuotationApprovalDrawer
          open={approvals.open}
          siteId={siteIdWatch}
          onClose={() => {
            setApprovals((pre) => {
              return {
                ...pre,
                open: false,
              };
            });
          }}
          onSubmit={(data: any) => {
            setApprovals({
              open: false,
              data: data,
            });
          }}
          setApprovalSubmissionStatus={(status: boolean) => { }}
          approvalData={approvals.data} // Pass the current approval data
        />
      )}
      {
        isAskDocumentsDrawerOpen &&
        <AskDocuments
          open={isAskDocumentsDrawerOpen}
          onClose={onAskDocumentsClose}
          onSubmit={handleDocumentsSubmit}
          documentData={documentData || { documents: [{ documentName: "", description: "", mandatory: false }] }} // Pass the current document data
        />
      }
    </>
  );
};

export default React.memo(RFQGeneralDetails);
