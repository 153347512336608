import React, { useState } from 'react';
import { Drawer, Table, Tooltip, Typography, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatCurrency, formatCurrency2 } from '../../../utils/Utils';

const { Text } = Typography;
const { Option } = Select;

interface MaterialOverrunDrawerProps {
    visible: boolean;
    onClose: () => void;
    data: any[];
    currency: string;
    categories: { categoryID: string; categoryName: string }[];
}

const MaterialWiseSpendAnalysisDrawer: React.FC<MaterialOverrunDrawerProps> = ({ visible, onClose, data, currency, categories }) => {
    const [selectedCategoryID, setSelectedCategoryID] = useState<string>(categories[0].categoryID);

    const filteredData = data.filter(item => item.categoryID === selectedCategoryID);

    const columns = [
        {
            title: 'Material ID',
            dataIndex: 'materialID',
            key: 'materialID',
        },
        {
            title: 'Material Name',
            dataIndex: 'materialName',
            key: 'materialName',
        },
        {
            title: 'Allocated Budget',
            dataIndex: 'budgetAllocated',
            key: 'budgetAllocated',
            render: (value: number) => (
                <>
                    {formatCurrency2(value, currency)}{' '}
                    <Tooltip title={formatCurrency(value, currency)}>
                        <InfoCircleOutlined style={{ fontSize: '10px', marginLeft: 4 }} />
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Actual Spent',
            dataIndex: 'spent',
            key: 'spent',
            render: (value: number) => (
                <>
                    {formatCurrency2(value, currency)}{' '}
                    <Tooltip title={formatCurrency(value, currency)}>
                        <InfoCircleOutlined style={{ fontSize: '10px', marginLeft: 4 }} />
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Variation',
            dataIndex: 'variation',
            key: 'variation',
            align: 'right' as 'right',
            render: (value: number, record: any) => (
                <Text style={{ color: record.indicator === 'up' ? 'red' : 'green', textAlign: 'right', display: 'block' }}>
                    {value}% 
                </Text>
            ),
        },
    ];

    const handleCategoryChange = (value: string) => {
        setSelectedCategoryID(value);
    };

    return (
        <Drawer
            title="Material Wise Spend Analysis"
            placement="right"
            onClose={onClose}
            open={visible}
            width={'75%'}
        >
            <div style={{ marginBottom: 16 }}>
                <label style={{ display: 'block', marginBottom: 8 }}>Filter by Category:</label>
                <Select
                    placeholder="Select Category"
                    style={{ width: 200 }}
                    value={selectedCategoryID}
                    onChange={handleCategoryChange}
                >
                    {categories.map(category => (
                        <Option key={category.categoryID} value={category.categoryID}>
                            {category.categoryName}
                        </Option>
                    ))}
                </Select>
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={{ pageSize: 10 }}
                rowKey="materialID"
                rowClassName={(record, index) => index % 2 === 0 ? '' : 'striped-row'}
            />
        </Drawer>
    );
};

export default MaterialWiseSpendAnalysisDrawer;
