import Swal from "sweetalert2";
import React, {  useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody, Badge } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import { Table, TableColumnsType } from "antd";
import MaterialModal from "./MaterialModal";
const PurchaseRequest_Materails: React.FC = (props: any) => {
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const [generalDetails, setGeneralDetails] = useState<any>();
  const [activeMaterial, setActiveMaterial] = useState<any>({
    action: null,
    index: null,
    item: null,
  });
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  const previousFormDate = watch();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "items",
  });

  
  const addNewRow = () => {
    setActiveMaterial({
      action: "add",
      index: null,
      item: null,
    });
  };


  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
        title: "Material Category",
        dataIndex: "categoryLabel",
        key: "categoryLabel",
        width: 200,
        fixed: "left",
      },
    {
      title: "MATERIAL NAME",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Req Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 150,
      },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveMaterial({
                action: "edit",
                index: index,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button size="sm" color="" onClick={() => remove(index)}>
            <Icon name="trash" />
          </Button>
        </>
      ),
    },
  ]);

  return(
    <>
    <Block>
      <form className="form-validate" onSubmit={handleSubmit(() => {})}>
        <Row>
          <div className="table-responsive poTable">
            <div style={{ width: screenSize - 120 + "px", margin: "auto" }}>
              {columns && (
                <Table
                  className="customTable"
                  columns={columns}
                  dataSource={fields}
                  scroll={{ x: "max-content" }}
                  locale={{
                    emptyText: (
                      <span
                        style={{
                          height: "300px",
                          color: "#333",
                          fontSize: "16px",
                        }}
                      >
                        Please click on "Add New Item" to add purchase order
                        lines.
                      </span>
                    ),
                  }}
                  bordered
                  size="small"
                  pagination={false}
                />
              )}
            </div>

            <Row
              style={{
                margin: "20px",
              }}
            >
              <Col className="justify-between">
                <a onClick={addNewRow} className="addNewItem">
                  <Icon name="plus-round" />
                  <span>Add New Item</span>
                </a>
              </Col>
            </Row>
          </div>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  if (fields.length == 0) {
                    Swal.fire({
                      icon: "info",
                      title: "Material",
                      text: "Please Add Material",
                    });
                    return;
                  }
                  props.next();
                }}
              >
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </Block>
   
    {activeMaterial.action && (
      <MaterialModal
        modal={!!activeMaterial.action}
        closeModal={() => {
          setActiveMaterial({
            action: null,
            index: null,
            item: null,
          });
        }}
        append={(data: any) => {
          const _data = fields.find((item: any) => {
            if (item.materialName == data.materialName)
          
              return true;
          });
        
          if (_data) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Material Already Added",
            });
            return;
          }
          append(data);
        }}
        update={(index: any, data: any) => {
          const ItemsList=[...fields]
          const _data = ItemsList?.filter((item: any,idx) => idx != index)?.find((item: any) => {
            if (item.materialName == data.materialName )
              return true;
          });
        
          if (_data) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Material Already Added",
            });
            return;
          }
          update(index, data);
        }}
        formData={activeMaterial}
      />
    )}
  </>
  )

  };

export default PurchaseRequest_Materails;