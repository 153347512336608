import React from 'react';
import { Avatar } from 'antd';
import {
    CheckCircleOutlined,
    FormOutlined,
    AuditOutlined,
    FileProtectOutlined,
    ShoppingCartOutlined,
    SafetyCertificateOutlined,
    FileDoneOutlined,
    RocketOutlined,
    CarOutlined,
    DollarCircleOutlined,
} from '@ant-design/icons';
import { colors } from '../../utils/Utils';

const P2PWidget: React.FC = () => {
    const timelineData = [
        { title: 'Create PR', icon: <FormOutlined />, avgDays: '1 Day' },
        { title: 'Quotation Requested', icon: <AuditOutlined />, avgDays: '2 Days' },
        { title: 'Quoted', icon: <FileProtectOutlined />, avgDays: '3 Days' },
        { title: 'Quotation Finalized', icon: <CheckCircleOutlined />, avgDays: '1 Day' },
        { title: 'Purchase Order Raised', icon: <ShoppingCartOutlined />, avgDays: '2 Days' },
        { title: 'Purchase Order Approved', icon: <SafetyCertificateOutlined />, avgDays: '1 Day' },
        { title: 'Invoiced', icon: <FileDoneOutlined />, avgDays: '4 Days' },
        { title: 'Dispatched', icon: <RocketOutlined />, avgDays: '5 Days' },
        { title: 'Delivered', icon: <CarOutlined />, avgDays: '3 Days' },
        { title: 'Fully Paid', icon: <DollarCircleOutlined />, avgDays: '2 Days' },
    ];

    return (
        <div>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Procure to Pay Cycle</h6>
                    <p>Tracking the end-to-end procurement process</p>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {timelineData.map((item, index) => (
                        <React.Fragment key={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    width: 100,
                                    height: '100px',
                                }}
                            >
                                <Avatar
                                    style={{
                                        backgroundColor: colors[index],
                                        color: '#fff',
                                        marginBottom: '8px',
                                        width: 50,
                                        height: 50,
                                    }}
                                    icon={item.icon}
                                />
                                <div>{item.title}</div>
                            </div>
                            {index < timelineData.length - 1 && (
                                <div style={{ flex: 1, position: 'relative', textAlign: 'center' }}>
                                    {/* avgDays positioned above the line */}
                                    <div style={{ position: 'absolute', top: '-24px', width: '100%' }}>
                                        <span style={{ color: '#888' }}>{item.avgDays}</span>
                                    </div>
                                    {/* Line in the middle */}
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '2px',
                                            backgroundColor: '#E5E9F2',
                                            position: 'absolute',
                                            top: '50%', // Center the line between Avatars
                                        }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default P2PWidget;
