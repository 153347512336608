import React, { useEffect, useState } from "react";
import { Badge, Button, Spinner } from "reactstrap";
import {
  Collapse,
  Descriptions,
  Popover,
  Timeline,
  Table,
  List,
  Modal,
  TableColumnsType,
  Empty,
} from "antd";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import { ApprovalData, Material } from "./types"; // Import your types
import quotationApis from "../../../api/master/quotations";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import rolesApi from "../../../api/master/roles";
import costCenterApis from "../../../api/master/costCenter";
import usersApis from "../../../api/master/users";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import adminOrgApis from "../../../api/master/adminOrg";
import Head from "../../../layout/head/Head";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = Collapse;

const RFQPreview: React.FC<{ prev: () => void }> = (props) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch: any = useDispatch();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const navigate = useNavigate();
  const { watch, register } = useFormContext();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const previousFormData = watch();
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [billingAddressList, setBillingAddressList] = useState<any>([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [roles, setRoles] = useState<any>([]);

  const onSubmit = async (data: any) => {
    setDisableSubmit(true);
    const _formData: any = new FormData();
    let _filesData: any = [];
    let quotationData: any = {
      ...previousFormData,
    };
    console.log("additionalData", quotationData);
    try {
      const quotationLines = await Promise.all(
        // const hasFile = material.file !== undefined && material.file !== null;
        previousFormData?.items.map(async (lineItem: any, index: number) => {
          let obj = { ...lineItem };
          let finalObj: any = {
            materialId: Number(obj?.materialName),
            materialName: obj?.materialLabel || "",
            categoryId: Number(obj?.materialCategory),
            categoryName: obj?.categoryLabel || "",
            uomId: Number(obj?.uomId),
            quantity: Number(obj?.quantity),
            // needByDate: Number(obj?.needByDate )|| "",
            deliveryAddressId: Number(obj?.deliveryAddressID) || null,
            deliveryDate: dayjs(obj?.needByDate).format("DD-MMM-YYYY") || "",
            // ...(hasFile && { fileIndex: fileCounter++ }) // Only include fileIndex if there's a file
          };
          if (obj?.file && obj?.file[0]) {
            _formData.append("files", obj?.file[0]);
            _filesData.push({
              fileIndex: _filesData.length,
              module: "Quotation",
              moduleFileType: "Special Instructions",
              level: "LINE_LEVEL",
            });
          } else if (obj?.dms?.length > 0) {
            _filesData.push({
              fileIndex: _filesData.length,
              module: "Quotation",
              moduleFileType: "Special Instructions",
              level: "LINE_LEVEL",
            });
          }
          if (previousFormData?.needByDateType === "Line Level") {
            finalObj.needByDate = dayjs(obj?.needByDate).format("YYYY-MM-DD");
          }
          if (previousFormData?.deliveryAddressType == "Line Level") {
            finalObj.deliveryAddressId = Number(obj?.deliveryAddressId);
          }
          if (obj?.prLineId) {
            finalObj.prLineId = Number(obj?.prLineId)
          }
          return finalObj;
        })
      );
      console.log("_formData", _formData)

      const quotationHeader = {
        prId: quotationData?.prId || null,
        rfqId: quotationData?.rfqId || null,
        billingAddressId: quotationData?.billingAddressId || null,
        quotationFor: quotationData?.quotationFor || "",
        quotationDescription:
          quotationData?.quotationDescription || "",
        costCenter: quotationData?.costCenter || undefined,
        lastSubmissionDate: dayjs(quotationData?.lastSubmissionDate).format("YYYY-MM-DD") || "",
        deadlineType: quotationData?.deadlineType || "",
        siteId: quotationData?.siteId || null,
        additionalCharges: quotationData?.additionalCharges || "",
        deliveryLevelType: quotationData?.deliveryAddressType || "",
        deliveryAddressId: quotationData?.deliveryAddressId || null,
        deliveryDateType: quotationData?.needByDateType || "",
        deliveryDate: quotationData?.needByDateType == 'LINE_LEVEL' ? undefined : quotationData?.needByDate || "",
        autoRelease: quotationData?.autoRelease || "",
        approvalRequired: quotationData?.approvalRequired || "",
        supplierDocsRequired:
          quotationData?.supplierDocsRequired || "",
      }

      let additionalData = {
        selectedSuppliers: Object.keys(quotationData)
          .filter((key) => key.startsWith("supplier_") && quotationData[key])
          .map((key) => parseInt(key.replace("supplier_", ""))),
        publishTo: "MARKET_PLACE"
      };

      const approvals = JSON.parse(quotationData.approvalsData);
      if (approvals?.data?.length > 0 && quotationHeader.approvalRequired) {
        _formData.append(
          "quotationApprovals",
          JSON.stringify(approvals?.data)
        );
      } else {
        _formData.append("quotationApprovals", JSON.stringify([]));
      }
      if (quotationData.documents !== undefined) {
        let requiredDocs = quotationData.documents.documents?.map(
          (doc: any, index: number) => ({
            documentName: doc.documentName || "",
            mandatory: doc.mandatory || 0,
          })
        )
        console.log("requiredDocs", requiredDocs)
        _formData.append("requiredDocs", JSON.stringify(requiredDocs))
      } else {
        _formData.append("requiredDocs", JSON.stringify([]));
      }

      console.log("_formData", _formData)

      if (quotationData.attachments) {
        quotationData.attachments.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          const file = new File([attachment], attachment.name, {
            type: attachment.type,
            lastModified: attachment.lastModified,
          });
          _formData.append(`files`, file);
          _filesData.push({
            fileIndex: _filesData.length,
            module: "Quotation",
            moduleFileType: "attachments",
            level: "HEADER_LEVEL",

          });
        });
      }
      console.log("_formData", _formData)

      if (quotationData.instructions) {
        const instructionsFile = new Blob([quotationData.instructions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          fileIndex: _filesData.length,
          module: "Quotation",
          moduleFileType: "instructions",
          level: "HEADER_LEVEL",
        });
      }

      console.log("_formData", _formData)


      if (quotationData.termsAndConditions) {
        const termsAndConditionsFile = new Blob([quotationData.termsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsandconditions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          fileIndex: _filesData.length,
          module: "Quotation",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
        });
      }
      // if (quotationData.deliveryAddressType != "Line Level") {
      //   quotationData.deliveryAddressId = quotationDatadeliveryAddressId;
      // }
      if (previousFormData?.needByDateType == "Order Level") {
        quotationData.needByDate = dayjs(quotationData.needByDate).format("YYYY-MM-DD");
      }

      let selectedSuppliers = Object.keys(quotationData)
        .filter((key) => key.startsWith("supplier_") && quotationData[key])
        .map((key) => parseInt(key.replace("supplier_", "")));

      _formData.append("quotationHeader", JSON.stringify(quotationHeader))
      _formData.append("quotationLines", JSON.stringify(quotationLines))
      _formData.append("docKeys", JSON.stringify(_filesData));

      console.log("selectedSuppliers", selectedSuppliers)

      _formData.append("additionalData", JSON.stringify(additionalData));
      console.log("_formData", _formData)
      createQuotations(_formData);
    } catch (error) {
      console.log(error);
      setDisableSubmit(false);
    }
  };

  const createQuotations = async (data: any) => {
    console.log("data:::Creation", data);
    try {
      const dataApI: any = await quotationApis.quotationsCreationMethod(data);
      const { status, error, response }: any = dataApI;
      if (status === true) {
        Swal.fire("Created!", "Quotation with RFQ ID - " + response.rfqId + " has been created", "success");
        navigate(`/view-rfqs`);
      }
      else {
        Swal.fire("Error!", error, "error");
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
    setDisableSubmit(false);
  };



  const requiredDocsContent = previousFormData?.supplierDocsRequired ? (
    <Table
      dataSource={previousFormData?.documents?.documents.map(
        (doc: any, index: number) => ({
          key: index + 1,
          sNo: index + 1,
          documentName: doc.documentName,
          mandatory: doc.mandatory ? "Yes" : "No",
        })
      )}
      columns={[
        {
          title: "S.No",
          dataIndex: "sNo",
          key: "sNo",
        },
        {
          title: "Document Name",
          dataIndex: "documentName",
          key: "documentName",
        },
        {
          title: "Mandatory to Upload",
          dataIndex: "mandatory",
          key: "mandatory",
        },
      ]}
      pagination={false}
      size="small"
      style={{ minWidth: "200px" }}
    />
  ) : null;


  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //   title: "MATERIAL ID",
    //   dataIndex: "materialID",
    //   key: "materialID",
    //   fixed: "left" as const,
    //   // width: 120,
    // },
    {
      title: "CATEGORY",
      dataIndex: "categoryLabel",
      key: "categoryLabel",
      fixed: "left" as const,
      // width: 120,
    },
    {
      title: "MATERIAL NAME",
      dataIndex: "materialLabel",
      key: "materialLabel",
      // width: 150,
    },
    // {
    //   title: "DESCRIPTION",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 200,
    // },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "UOM",
      dataIndex: "uomLabel",
      key: "uomLabel",
    },
    // {
    //   title: "NEED BY DATE",
    //   dataIndex: "needByDate",
    //   key: "needByDate",
    // },
  ]);

  // const suppliers = Object.keys(quotationData || {})
  //   .filter(
  //     (key) => key.startsWith("supplier_") && quotationData[key]
  //   )
  //   .map((key) => key.replace("supplier_", "Supplier "));


  const suppliers: any = previousFormData.SupplierNamesSelected;
  console.log("suppliers", suppliers)


  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBillingAddress = async () => {
    try {
      const { data, status } = await adminOrgApis.fetchAdminOrg();
      if (status) {
        let billingAddressOptions = data.map((item: any) => ({
          label: item.name,
          value: item.orgID.toString(),
        }));
        setBillingAddressOptions(billingAddressOptions);
        setBillingAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(
        moduleID,
        Number(previousFormData.siteId)
      );
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Quotation"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    console.log("previousFormData", previousFormData);
    const { currencyObj, signatureObj } = previousFormData;

    fetchModuleConfig();
    fetchBillingAddress();
    fetchDeliveryAddress();
    fetchCostCenter();
    fetchSites();
  }, []);
  const ShowAddressPopOver = ({ id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
        <li>{address.addressLine1}</li>
        <li>{address.addressLine2}</li>
        <li>
          {address.city}, {address.stateName}, {address.countryName} -{" "}
          {address.pincode}
        </li>
      </ul>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(previousFormData?.approvalsData);
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      previousFormData?.approvalRequired == "false"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.data?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };
  return (
    <>
      <Head title="New - Quotation " />
      <div className="content clearfix">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="General Details" key="1">
              <Descriptions title="" className="custom-description-title">
                <Descriptions.Item label="Quotation For">
                  {previousFormData.quotationFor}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {previousFormData.quotationDescription}
                </Descriptions.Item>
                <Descriptions.Item label="Cost Center">
                  {
                    costCenterOptions.find(
                      (item) =>
                        item.value == previousFormData.costCenter
                    )?.label
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Last Submission Date">
                  <span className="badge-dim badge bg-primary rounded-pill">
                    <strong>
                      {dayjs(previousFormData.lastSubmissionDate).format("DD-MM-YYYY")}
                    </strong>
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Time Limit">
                  {previousFormData.deadlineType}
                </Descriptions.Item>
                <Descriptions.Item label="Site Name">
                  {
                    sitesOptions.find(
                      (item) => item.value == previousFormData.siteId
                    )?.label
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Approvals">
                  <ApprovalTimelineContent />
                </Descriptions.Item>
                <Descriptions.Item label="Additional Charges">
                  {" "}
                  {previousFormData.additionalCharges}
                </Descriptions.Item>
                <Descriptions.Item label="Auto Release">
                  {previousFormData.autoRelease === "Y" ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Billing Address">
                  <ShowAddressPopOver
                    id={previousFormData?.billingAddressId}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Delivery Address">
                  {previousFormData.deliveryAddressType === "LINE_LEVEL"
                    ? "Line Level"
                    : "Order Level"}
                </Descriptions.Item>
                <Descriptions.Item label="Documents Required From Supplier">
                  {previousFormData.supplierDocsRequired === "Y" ? (
                    <Popover
                      content={requiredDocsContent}
                      title={
                        <span style={{ color: "#2263b3" }}>Required Documents</span>
                      }
                    >
                      <span
                        style={{
                          color: "#2263b3",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Yes
                      </span>
                    </Popover>
                  ) : (
                    "No"
                  )}
                </Descriptions.Item>
                {previousFormData.deliveryAddressType === "ORDER_LEVEL" && (
                  <>
                    <Descriptions.Item label="Delivery Address">
                      <ShowAddressPopOver
                        id={previousFormData?.deliveryAddressId}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Need By Date">
                      <span className="badge-dim badge bg-primary rounded-pill">
                        <strong>
                          {dayjs(previousFormData.needByDate).format("DD-MM-YYYY")}
                        </strong>
                      </span>
                    </Descriptions.Item>
                  </>

                )}
              </Descriptions>
            </Panel>
            <Panel header="Materials" key="2">
              <Table
                columns={columns}
                dataSource={previousFormData?.items}
                scroll={{ x: "max-content" }}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <div className="expandableBody">
                      <div style={{ flex: 1, textAlign: "left" }}>
                        <p style={{ margin: 0 }}>{record.description}</p>
                        {record.file && record.file[0] && (
                          <div>
                            <PaperClipOutlined /> &nbsp;
                            <a
                              href={URL.createObjectURL(record.file[0])}
                              download={record.file[0]?.name}
                            >
                              {record.file[0]?.name}
                            </a>
                          </div>
                        )}
                      </div>
                      {previousFormData?.deliveryAddressType ===
                        "LINE_LEVEL" &&
                        record.deliveryAddressId && (
                          <div
                            className="lineAddress"
                            style={{ flex: 1 }}
                          >
                            <h6>Delivery Address</h6>
                            <ShowAddressPopOver
                              id={record.deliveryAddressId}
                            />
                          </div>
                        )}
                    </div>
                  ),
                }}
                bordered
                size="small"
                pagination={false}
                locale={{ emptyText: "No materials added" }}
              />
            </Panel>
            <Panel header="Other Information" key="3">
              <Descriptions
                title="Other Information"
                className="custom-description-title"
              >
                <Descriptions.Item label="Suppliers">
                  <List
                    size="small"
                    dataSource={suppliers}
                    renderItem={(item: any) => <List.Item>{item}</List.Item>}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Instructions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstructionsVisible(true)}
                  >
                    View Instructions
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Terms and Conditions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setTermsVisible(true)}
                  >
                    View Terms and Conditions
                  </span>
                </Descriptions.Item>
                {previousFormData?.attachments?.length > 0 && (
                  <Descriptions.Item label="">
                    <div>
                      <div className="">
                        Attachments &nbsp;
                        <PaperClipOutlined />
                      </div>
                      <div>
                        {previousFormData.attachments?.map(
                          (attachment: any, index: number) => (
                            <div
                              key={index}
                              className="w-100 d-flex justify-content-between"
                            >
                              <div>
                                <strong>{index + 1}.</strong>
                                &nbsp;
                                {attachment.name}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </div>
                              <div>
                                <a
                                  href={URL.createObjectURL(
                                    attachment.originFileObj
                                  )}
                                  download={attachment.name}
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <DownloadOutlined />
                                </a>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          </Collapse>
          <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.instructions ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: previousFormData?.instructions,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No instructions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.termsAndConditions ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: previousFormData?.termsAndConditions,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No terms and conditions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default React.memo(RFQPreview);
