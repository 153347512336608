import makeApiCall from "./axiosIndex";
import purchaseOrderApis from "./PurchaseOrder";
const purchaseRequestApis = {
    purchaseRequestCreation: (data: any) =>
        makeApiCall({
            url: "/purchase-request/new",
            method: "post",
            data,
        }),

    fetchList: (): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/purchase-request/list`,
        }),
    getById: (prId: any): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/purchase-request/getById`,
            params: { prId }
        }),

    fetchPendingApprovals: (): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/purchase-request/pendingApprovals`,
        }),
    approve: (prId: any): Promise<any> =>
        makeApiCall<any>({
            method: "put",
            url: `/purchase-request/approve`,
            params: { prId },
        }),
    reject: (prId: any, reason: any): Promise<any> =>
        makeApiCall<any>({
            method: "put",
            url: `/purchase-request/reject`,
            params: { prId, reason },
        }),

    newVersion: (prId: any, data: any) =>
        makeApiCall({
            url: "/purchase-request/newVersion",
            method: "post",
            params: { prId },
            data,
        }),
    convertToRFQ: (prId: any): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/purchase-request/convertToRFQ`,
            params: { prId },
        }),
    prTracking: (prId: any): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/purchase-request/prTracking`,
            params: { prId }
        }),
};

export default purchaseRequestApis;
