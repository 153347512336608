import React from 'react';
import { Card } from 'antd';
import { FileTextOutlined, ClockCircleOutlined, CheckCircleOutlined, FileProtectOutlined, FileDoneOutlined, RiseOutlined } from '@ant-design/icons';
import { QuotationAnalyticsProps } from './interfaces';

const data = [
    { parameter: 'totalRFQsGenerated', name: 'Total RFQs Generated', count: 50, icon: <FileDoneOutlined />, percentage: false },
    { parameter: 'totalQuotesReceived', name: 'Total Quotes Received', count: 200, icon: <FileTextOutlined />, percentage: false },
    { parameter: 'averageQuotesPerRequest', name: 'Average Quotes Per Request', count: 4, icon: <CheckCircleOutlined />, percentage: false },
    { parameter: 'averageTimeToReceiveQuote', name: 'Average Time to Receive a Quote', count: "1 Day", icon: <ClockCircleOutlined />, percentage: false },
    { parameter: 'avgCostSavings', name: 'Average Cost Savings', count: 8.3, icon: <RiseOutlined />, percentage: true },
    { parameter: 'totalAwardedSuppliers', name: 'Total Awarded Suppliers', count: 30, icon: <FileProtectOutlined />, percentage: false },
];

const QuotationSummaryAnalytics:  React.FC<QuotationAnalyticsProps> = ({ site, dateRange, customDateRange }) => {
    return (
        <>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Quotation Summary</h6>
                    <p>Comprehensive Overview of quotations to conversions</p>
                </div>
            </div >
            <div>
                {data.map((item, index) => (
                    <div key={index} style={{
                        display: 'flex', alignItems: 'center',
                        backgroundColor: index % 2 === 0 ? '#f1f1f1' : '#ffffff',
                        padding: 8
                    }}>
                        <div style={{
                            fontSize: 22,
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#333'
                        }}>
                            {item.icon}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', flex: 1 }}>
                            <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}>{item.name}</span>
                            <div style={{ flex: 1, borderBottom: '1px solid #dbdfea', marginRight: 10 }}></div>
                            <span style={{ fontSize: '20px' }}>
                                {item.count}
                                {item.percentage && <span>%</span>}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default QuotationSummaryAnalytics;
