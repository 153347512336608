import React, { useState, useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col, Button, Modal, ModalBody, Spinner } from "reactstrap";
import Head from "../../layout/head/Head";
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
} from "../../components/Component";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
    PaperClipOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    DeleteOutlined,
    UploadOutlined,
    PlusSquareOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import {
    Form,
    Input,
    InputNumber,
    Table,
    Collapse as AntCollapse,
    Button as AntButton,
    Descriptions,
    Modal as AntModal,
    Spin,
    Flex,
    Drawer,
    Upload,
    Select,
    Popover,
    DatePicker,
    TableColumnsType,
    Timeline,
    Empty,
    UploadFile,
    List,
    Checkbox
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "./QuotationsAside";
import statusMapper from "../purchase-orders/StatusMapper";
// import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import usersApis from "../../api/master/users";
import quotationApis from "../../api/master/quotations";
import { useLocation } from "react-router-dom";
import QuotationApprovalDrawer from "./newQuotations/QuotationApprovalDrawer";
import { AddressDetails, Material, SelectOptions } from "./newQuotations/types";
import Dragger from "antd/es/upload/Dragger";
import costCenterApis from "../../api/master/costCenter";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import MaterialModal from "./MaterialModal";
import supplierApis from "../../api/master/supplier";

const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
    cessName: string;
    cessType?: string;
    cessPercentage?: string;
    cessAmount?: string;
};
interface TaxConfiguration {
    taxType: string;
    gstType?: string; //IGST or CGST+SGST
    cess?: Cess[];
}

interface CurrencyData {
    description: string;
    currency_code: string;
    currency_symbol: string;
    currency_format: string;
    example: string;
}

const ViewDetailsEdit: FC<any> = () => {
    const [isLoading, setIsLoading] = useState(true);
    let { qid }: any = useParams();
    const navigate = useNavigate();
    const [OriginalData, setOriginalData] = useState<any>({});
    const [rfqStatus, setRfqStatus] = useState<any>("");
    const [sm, setSm] = useState(false);
    const [tab, setTab] = useState("1");
    const [screenSize, setScreenSize] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [materialData, setMaterialData] = useState<any>({});
    const [supplierList, setSupplierList] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
    const [newAttachments, setNewAttachments] = useState<any>([]);
    const [DMS, setDMS] = useState<any>([]);
    const [editLineRow, setEditLineRow] = useState<any>();
    const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState<any>("");
    const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
    const [instructions, setInstructions] = useState("");
    const [newInstructions, setNewInstructions] = useState<any>("");
    const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const [approverIds, setApproverIds] = useState<any>([]);
    const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
        useState(false);

    const methods = useForm({
        mode: "onChange",
    });
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [supplierData, setSupplierData] = useState<any>([]);

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = methods;

    const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [showSubmitBtm, setShowSubmitBtm] = useState(false);


    const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
    const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>([]);
    const [billingAddressList, setBillingAddressList] = useState<AddressDetails[]>([]);
    const [billingAddressOptions, setBillingAddressOptions] = useState<SelectOptions[]>([]);
    const [suppliers, setSuppliers] = useState<any[]>([]);
    const [selectedArrayData, setSelectedArrayData] = useState([]);

    const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
        SelectOptions[]
    >([]);

    const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);


    useEffect(() => {
        fetchDetails();
        fetchAddresses();
        fetchCostCenter();
        fetchSites();
        viewChange();
        fetchDeliveryAddress();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerClick = () => setSm(false);
        document
            .getElementsByClassName("nk-header")[0]
            ?.addEventListener("click", headerClick);

        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
            document
                .getElementsByClassName("nk-header")[0]
                ?.removeEventListener("click", headerClick);
        };
    }, []);

    const viewChange = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setSm(false);
        }
    };

    const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
        setNewAttachments((pre: any) => {
            return [...pre, ...info.fileList];
        });
        setShowSubmitBtm(true);
    };

    const fetchHtmlContentFromUrl = async (url: string) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "text/html",
                },
            });

            return response?.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };


    const fetchSuppliers = async () => {
        try {
            const { data, status, message } = await supplierApis.fetchSupplier();
            console.log("status:", status);
            if (status) {
                console.log("1111111::::");
                let formatedData = data.map((item: any) => ({
                    supplierName: item.supplierName, // Adjust this based on the actual key in your data
                    supplierID: item?.supplierOrgID ? item?.supplierOrgID?.toString() : item?.supplierID?.toString(),
                }));
                console.log("formatedData", formatedData)
                setSuppliers(formatedData);
                return formatedData;
            } else {
                throw new Error(message);
            }
        } catch (error) {

        }
    }

    const fetchDeliveryAddress = async () => {
        try {
            const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
            if (status) {
                let deliveryAddressOptions = data.map((item: any) => ({
                    label: item.deliveryLocationName,
                    value: item.id.toString(),
                }));
                setDeliveryAddressOptions(deliveryAddressOptions);
                setDeliveryAddressList(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDetails = async () => {
        fetchSuppliers();
        try {
            const res = await quotationApis.getById(qid);
            const { response, status, message } = res;
            if (status) {
                const data = response.data
                setOriginalData(data);
                console.log("data", data)
                setRfqStatus(data?.quotationHeader.status);
                console.log("rfqStatus", data?.quotationHeader.status)
                if (data?.quotationApprovals) {
                    let ids = data?.quotationApprovals?.map((item: any) => item?.userId);
                    ids = ids?.filter((item: any) => item?.status != "Approved");
                    setApproverIds(ids);
                }

                if (data?.addtionalData.selectedSuppliers) {
                    console.log("Suppliers:::", suppliers);
                    const selectedSuppliersSet = new Set(data?.addtionalData.selectedSuppliers.map(String));
                    const filteredSuppliers = suppliers.filter(supplier => selectedSuppliersSet.has(supplier.supplierID));
                    console.log("filteredSuppliers", filteredSuppliers)
                    setSupplierData(filteredSuppliers);
                    setSelectedArrayData(data?.addtionalData.selectedSuppliers)
                }

                if (data?.quotationHeader?.dms?.length > 0) {
                    setDMS(data?.quotationHeader?.dms);
                    const _termsAndConditions = data?.quotationHeader?.dms?.find(
                        (item: any) => item.moduleFileType == "termsAndConditions"
                    );
                    if (_termsAndConditions) {
                        const data: any = await fetchHtmlContentFromUrl(
                            _termsAndConditions?.filePath
                        );
                        setTermsAndConditions(data);
                        setNewTermsAndConditions(data);
                    }
                    const _instructions = data?.quotationHeader?.dms?.find(
                        (item: any) => item.moduleFileType == "instructions"
                    );
                    if (_instructions) {
                        const data: any = await fetchHtmlContentFromUrl(
                            _instructions?.filePath
                        );
                        setInstructions(data);
                        setNewInstructions(data);
                    }
                    const _attachments = data?.quotationHeader?.dms?.filter(
                        (item: any) => item.moduleFileType == "attachments"
                    );
                    if (_attachments) {
                        setAttachments(
                            _attachments.map((attachment: any) => ({
                                docId: attachment?.docId,
                                fileName: attachment?.fileName,
                                link: attachment?.filePath,
                                moduleFileType: attachment?.moduleFileType,
                                module: attachment?.module,
                                label: "attachments",
                                isActive: true,
                            }))
                        );
                    }
                }
                setMaterialData(data);
                const LineItems = data?.quotationLines?.map((item: any) => {
                    const file = item.dms?.find((doc: any) => doc.quotationLineId == item.quotationLineId);
                    console.log("file", file)
                    if (file) {
                        item.file = file;
                        item.attachment = true;
                    } else item.attachment = false;
                    return item;
                });
                setData(LineItems);
                console.log("lines", LineItems)
                setShowSubmitBtm(false);
                setDeletedAttachments([]);
                setNewAttachments([]);
                // setCurrency({
                //     description: currencyCode,
                //     currency_code: currencyCode,
                //     currency_symbol: currencySymbol,
                //     currency_format: currencyFormat,
                //     example: currencyCode,
                // });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }


    const fetchCostCenter = async () => {
        try {
            const { data, status } = await costCenterApis.fetchCostCenter();
            if (status) {
                const costCenterOptions = data.map((item: any) => ({
                    label: item.costCenterName,
                    value: item.id.toString(),
                }));
                setCostCenterOptions(costCenterOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchAddresses = async () => {
        try {
            const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
            if (status) {
                const addressOptions = data.map((item: any) => ({
                    label: item.deliveryLocationName,
                    value: item.id.toString(),
                }));
                setBillingAddressList(data);
                setBillingAddressOptions(addressOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchSites = async () => {
        try {
            const data = await usersApis.getCurrentUser()
            if (data) {
                let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
                    label: item.siteName,
                    value: item.siteID.toString(),
                }));
                setSitesOptions(sitesOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const [columns, setColumns] = useState<TableColumnsType<any>>([
        // {
        //     ...Table.EXPAND_COLUMN,
        //     fixed: "left",
        // },
        {
            title: "MATERIAL ID",
            dataIndex: "materialId",
            key: "materialId",
            fixed: "left" as const,
            // width: 120,
        },
        {
            title: "CATEGORY",
            dataIndex: "categoryName",
            key: "categoryName",
            fixed: "left" as const,
            // width: 150,
        },
        {
            title: "MATERIAL NAME",
            dataIndex: "materialName",
            key: "materialName",
            // width: 200,
        },
        // {
        //     title: "DESCRIPTION",
        //     dataIndex: "description",
        //     key: "description",
        //     width: 200,
        // },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "qty",
        },
        {
            title: "UOM",
            dataIndex: "uomName",
            key: "uomName",
            // width: 150,
        },
        {
            title: "NEED BY DATE",
            dataIndex: "deliveryDate",
            key: "deliveryDate",
            hidden: materialData?.quotationHeader?.deliveryLevelType === "LINE_LEVEL" ? false : true,
            render: (text) => dayjs(text).format("DD-MMM-YYYY")
        },
        {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 150,
            hidden: ["REJECTED"].includes(rfqStatus),
            render: (text, record: any, index) =>
                !["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                    <Button
                        size="sm"
                        color=""
                        onClick={() => {
                            setEditLineRow(record);
                        }}
                    >
                        <Icon name="edit" />
                    </Button>
                ),
        },
    ]);

    // const checkboxValues = watch();

    // const [selectedSuppliers, setSelectedSuppliers]:any = useState();



    // useEffect(() => {
    //     setSelectedSuppliers(new Set(selectedSuppliers?.map(String)))
    //     const newSelectedSuppliers = Object.keys(checkboxValues)
    //         .filter(key => checkboxValues[key])
    //         .map(key => key.replace('supplier_', ''));
    //     setSelectedSuppliers(new Set(newSelectedSuppliers));
    //     setShowSubmitBtm(true);
    // }, [checkboxValues]);

    const handleCheckboxChange = (supplierID: any) => (event: any) => {
        console.log("PreviousData::selectedArrayData", selectedArrayData)
        console.log("supplierID", supplierID);
        let isChecked = event.target.checked;
        setValue(`supplier_${supplierID}`, isChecked);
        console.log("isChecked", isChecked)
        setSelectedArrayData((prevSelectedArrayData: any) => {
            if (isChecked) {
                // Add ID to the array
                console.log("add", [...prevSelectedArrayData, Number(supplierID)])
                return [...prevSelectedArrayData, Number(supplierID)];
            } else {
                // Remove ID from the array
                console.log("remove", prevSelectedArrayData.filter((id: any) => id !== Number(supplierID)))
                return prevSelectedArrayData.filter((id: any) => id !== Number(supplierID));
            }
        });
        setShowSubmitBtm(true); // Show submit button when any checkbox is changed
    };


    const renderSupplierCheckboxes = (suppliers: { supplierName: string, supplierID: string }[]) => {
        let selectedSuppliersSet: any = new Set(OriginalData.addtionalData.selectedSuppliers.map((id: any) => String(id)));
        return suppliers.map((supplier) => (
            <div key={supplier.supplierID} style={{ marginBottom: '15px' }}>
                <Controller
                    name={`supplier_${supplier.supplierID}`}
                    control={methods.control}
                    defaultValue={selectedSuppliersSet.has(supplier.supplierID)}
                    render={({ field }) => (
                        <Checkbox {...field} checked={field.value}
                            onChange={handleCheckboxChange(supplier.supplierID)}
                            className="custom-checkbox">
                            {supplier.supplierName}
                        </Checkbox>
                    )}
                />
            </div>
        ));
    };



    const ApprovalTimelineContent = () => {
        const approvalsData = OriginalData?.quotationApprovals;
        console.log("ApprovalData", OriginalData)
        if (!approvalsData || approvalsData?.length === 0) {
            return <Badge color="outline-danger">No</Badge>;
        }

        return (
            <Popover
                content={
                    <Timeline className="approval-timeline">
                        {approvalsData?.map((approval: any, index: number) => {
                            const isApproved = approval.status == "APPROVED";
                            const isRejected = approval.status == "REJECTED";
                            return (
                                <Timeline.Item
                                    key={index}
                                    dot={
                                        isApproved ? (
                                          <CheckCircleOutlined style={{ color: "green" }} />
                                        ) : isRejected? (
                                          <CloseCircleOutlined  style={{ color: "red" }} />
                                        ):undefined
                                      }
                                >
                                    {approval?.userName}
                                    <div className="designation">{approval?.roleName}</div>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                }
                title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
            >
                <span
                    style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    {/* <Badge color="outline-success">Yes</Badge> */}
                    Yes
                </span>
            </Popover>
        );
    };


    const billingAddressContent = OriginalData?.quotationHeader?.billingAddressName ? (
        <div className="billingAddress_align">
            <p>
                {OriginalData.quotationHeader.billingAddressName?.addressLine1},{" "}
                {OriginalData.quotationHeader.billingAddressName?.addressLine2}
            </p>
            <p>
                {OriginalData.quotationHeader.billingAddressName?.city},{" "}
                {OriginalData.quotationHeader.billingAddressName?.district?.districtName},{" "}
                {OriginalData.quotationHeader.billingAddressName?.state?.stateName},{" "}
                {OriginalData.quotationHeader.billingAddressName?.countryInfo?.countryName} -{" "}
                {OriginalData.quotationHeader.billingAddressName?.pincode}
            </p>
        </div>
    ) : null;

    const orderDeliveryAddressContent = OriginalData?.quotationHeader
        ?.deliveryAddressName ? (
        <div className="billingAddress_align">
            <p>
                {OriginalData.quotationHeader?.deliveryAddressName?.addressLine1},{" "}
                {OriginalData.quotationHeader?.deliveryAddressName?.addressLine2}
            </p>
            <p>
                {OriginalData.quotationHeader.deliveryAddressName?.cityName},{" "}
                {OriginalData.quotationHeader.deliveryAddressName?.district.districtName},{" "}
                {OriginalData.quotationHeader.deliveryAddressName?.state?.stateName},{" "}
                {OriginalData.quotationHeader.deliveryAddressName?.countryInfo?.countryName} -{" "}
                {OriginalData.quotationHeader.deliveryAddressName?.pincode}
            </p>
        </div>
    ) : null;



    useEffect(() => {
        if (editLineRow) {
            setAddNewRowModel(true);
        }
    }, [editLineRow]);



    const requiredDocsContent = OriginalData?.requiredDocs ? (
        <Table
            dataSource={OriginalData?.requiredDocs?.map(
                (doc: any, index: number) => ({
                    key: index + 1,
                    sNo: index + 1,
                    documentName: doc.documentName,
                    mandatory: doc.mandatory ? "Yes" : "No",
                })
            )}
            columns={[
                {
                    title: "S.No",
                    dataIndex: "sNo",
                    key: "sNo",
                },
                {
                    title: "Document Name",
                    dataIndex: "documentName",
                    key: "documentName",
                },
                {
                    title: "Mandatory to Upload",
                    dataIndex: "mandatory",
                    key: "mandatory",
                },
            ]}
            pagination={false}
            size="small"
            style={{ minWidth: "200px" }}
        />
    ) : null;




    const SubmitChanges = async () => {
        try {
            const _formData = new FormData();
            const updatedLineItems: any = [];
            const newLineItems: any = [];
            const deletedLineItems: any = [];

            const newfiledata: any = [];
            const deletedFileData: any = [...deletedAttachments];
            const updatedFileData: any = [];

            materialData.addtionalData.selectedSuppliers = selectedArrayData

            materialData?.quotationLines?.forEach((data: any) => {
                console.log("data", data)
                if (!data?.quotationLineId) {
                    // new item
                    console.log("newLINES::::::IFF")
                    // newLineItems.push({
                    //     materialId: data.materialID || null,
                    //     materialName: data.materialName || "",
                    //     categoryId: data.categoryID || null,
                    //     categoryName: data.categoryName || "",
                    //     uomId: data.uomID || null,
                    //     quantity: data.qty || "",
                    //     needByDate: data.needByDate || "",
                    //     deliveryAddressId: data.deliveryAddressID || null,
                    //     deliveryDate: data.needByDate || "",
                    // });
                    if (data?.file && data?.file[0]) {
                        data.fileIndex = newfiledata.length
                        const fileLine = new File([data.file[0]], data.file[0].name);
                        _formData.append("files", fileLine);
                        // _formData.append("files", data?.file[0]);
                        newfiledata.push({
                            fileIndex: newfiledata.length,
                            module: "Quotation",
                            moduleFileType: "Special Instructions",
                            level: "LINE_LEVEL",
                        });
                    }
                } else {
                    // check for update
                    console.log("ELSE::::", data)

                    const OData = OriginalData?.quotationLines?.find(
                        (item: any) => item.quotationLineId == data.quotationLineId
                    );
                    console.log("OData", OData)
                    let updatedItem: any = {};
                    updatedItem.quotationLineId = data.quotationLineId;
                    // if (Number(OData.quantity) !== Number(data.quantity)) {
                    //     updatedItem.quantity = Number(data.quantity);
                    // }


                    // if (Number(OData.categoryId) !== Number(data.categoryId)) {
                    //     updatedItem.categoryId = Number(data.categoryId);
                    // }

                    // if (OData.description !== data.description) {
                    //     updatedItem.description = data.description;
                    // }



                    if (Object.keys(updatedItem).length > 0) {
                        //     if (OriginalData?.needByDateType == "Line Level") {
                        //         updatedItem.needByDate = dayjs(data.needByDate).format(
                        //             "YYYY-MM-DD"
                        //         );
                        //     }
                        //     if (OriginalData?.deliveryAddressType == "Line Level") {
                        //         updatedItem.deliveryAddressId = Number(data.deliveryAddressId);
                        //     }
                        //     updatedItem.materialId = Number(data.materialId);
                        updatedItem.quotationLineId = data.quotationLineId;
                        //     updatedItem.uuid = OData.uuid;
                        updatedLineItems.push(updatedItem);
                    }

                    if (data?.file && data?.file[0]) {

                        if (OData?.dms[0]?.docId) {
                            deletedFileData.push({
                                docId: OData?.dms[0]?.docId,
                            });
                        }
                        data.fileIndex = newfiledata.length
                        newfiledata.push({
                            fileIndex: newfiledata.length,
                            module: "Quotation",
                            moduleFileType: "Special Instructions",
                            quotationLineId: OData.quotationLineId,
                            level: "LINE_LEVEL",
                        });
                        console.log("newfiledata");

                        console.log("data.file::::", data.file)
                        const fileLine = new File([data.file[0]], data.file[0].name);
                        // if(fileLine){

                        // }
                        console.log("new File([data.file[0]], data.file[0].fileName", fileLine)
                        _formData.append("files", fileLine);
                    }
                }
            });


            newAttachments.forEach((attachment: any, index: number) => {
                if (!attachment) {
                    return;
                }
                console.log("attachment", attachment)
                const file = new File([attachment], attachment.name, {
                    type: attachment.type,
                    lastModified: attachment.lastModified,
                });

                _formData.append("files", file);
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "Quotations",
                    moduleFileType: "attachments",
                    level: "HEADER_LEVEL",
                });
            });

            const existingLineItems: any = [];
            OriginalData?.quotationLines?.forEach((item: any) => {
                if (item?.quotationLineId) {
                    const obj: any = {
                        quotationLineId: item.quotationLineId,
                        materialId: item.materialId,
                    };
                    if (OriginalData?.needByDateType == "Line Level") {
                        obj.needByDate = item.needByDate;
                    }
                    if (OriginalData?.deliveryAddressType == "Line Level") {
                        obj.deliveryAddressId = item.deliveryAddressId;
                    }
                    existingLineItems.push(obj);
                }
            });

            if (isInstructionsChanged) {
                const instructionsFile = new Blob([newInstructions], {
                    type: "text/html",
                });
                _formData.append(
                    `files`,
                    new File([instructionsFile], "instructions.html", {
                        type: "text/html",
                    })
                );
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "Quotations",
                    moduleFileType: "instructions",
                    level: "HEADER_LEVEL",
                });
                setIsInstructionsChanged(false);
                const _instructions = DMS?.find(
                    (item: any) => item.moduleFileType == "instructions"
                );
                if (_instructions?.docId) {
                    deletedFileData.push({
                        docId: _instructions.docId,
                    });
                }
            }
            if (isTermsAndConditionsChanged) {
                const termsAndConditionsFile = new Blob([newTermsAndConditions], {
                    type: "text/html",
                });
                _formData.append(
                    `files`,
                    new File([termsAndConditionsFile], "termsAndConditions.html", {
                        type: "text/html",
                    })
                );
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "Quotations",
                    moduleFileType: "termsAndConditions",
                    level: "HEADER_LEVEL",
                });
                setIsTermsAndConditionsChanged(false);
                const _termsAndConditions = DMS?.find(
                    (item: any) => item.moduleFileType == "termsAndConditions"
                );
                if (_termsAndConditions?.docId) {
                    deletedFileData.push({
                        docId: _termsAndConditions.docId,
                    });
                }
            }

            let lastSubmissionDate = dayjs(materialData.quotationHeader?.lastSubmissionDate).format("YYYY-MM-DD");
            materialData.quotationHeader.lastSubmissionDate = lastSubmissionDate;
            const filesData = {
                newfiledata,
                deletedFileData,
                updatedFileData,
            };

            _formData.append("filesData", JSON.stringify(filesData));

            // _formData.append("files", JSON.stringify([]));

            _formData.append(
                "quotationHeader",
                JSON.stringify(materialData.quotationHeader)
            );
            _formData.append(
                "quotationLines",
                JSON.stringify(materialData.quotationLines)
            );
            _formData.append(
                "requiredDocs",
                JSON.stringify(materialData.requiredDocs)
            );
            _formData.append(
                "quotationApprovals",
                JSON.stringify(materialData.quotationApprovals)
            );

            _formData.append(
                "additionalData", JSON.stringify(materialData.addtionalData)
            )
            console.log("MaterialDAta::::", materialData);

            // return

            const res = await quotationApis.newVersion(_formData);
            const { status, message }: any = res;
            if (status === true) {
                fetchDetails();
                Swal.fire("Updated!", message, "success");
                navigate("/view-rfqs");
            } else {
                Swal.fire("Error!", message, "error");
            }
        } catch (error) {
            console.log(error);
        }
        setDisableSubmit(false);
    };



    return (
        <>
            <Head title="View Quotation Details" />
            <Modal
                isOpen={generalInformationEdit}
                toggle={() => setGeneralInformationEdit(false)}
                className="modal-dialog-centered"
                size="xl"
                backdrop="static"
            >
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setGeneralInformationEdit(false);
                    }}
                    className="close"
                >
                    <Icon name="cross-sm"></Icon>
                </a>

                <ModalBody>
                    <>
                        <h5 className="title">Update General Information</h5>
                        <div style={{ marginTop: "15px" }}></div>

                        <FormProvider {...methods}>
                            <form
                                className="content clearfix"
                                onSubmit={handleSubmit((data) => {

                                    setMaterialData((pre: any) => {
                                        console.log("pre", pre)
                                        const newQuotationHeader = {
                                            ...pre.quotationHeader, // Keep existing values
                                            ...data,
                                            siteName: sitesOptions.find(
                                                (item) => item.value == data?.siteId
                                            )?.label,
                                            costCenterName: costCenterOptions.find(
                                                (item) => item.value == data?.costCenter
                                            )?.label,
                                        }

                                        console.log("updatedData", newQuotationHeader)

                                        const updatedMaterialData = {
                                            ...pre, // Keep existing data
                                            quotationHeader: newQuotationHeader, // Update quotationHeader
                                        };

                                        return {
                                            ...pre,
                                            ...data,
                                            ...updatedMaterialData,
                                        };
                                    });
                                    setGeneralInformationEdit(false);
                                    setAddNewRowModel(false);
                                    setEditLineRow(null);
                                    console.log(data);
                                    setShowSubmitBtm(true);
                                })}
                            >
                                <Row className="gy-4">
                                    <Col lg="4">
                                        <FormSelect
                                            name="quotationFor"
                                            label="Quotation For"
                                            options={[{ label: 'Material', value: 'Material' }, { label: 'Service', value: 'Service' }]}
                                            required={true}
                                            placeholder="Select Quotation Type"
                                        />
                                    </Col>
                                    <Col lg="8">
                                        <FormInput
                                            name="quotationDescription"
                                            label="Quotation Description"
                                            required={true}
                                            type="text"
                                            minLength={2}
                                            maxLength={100}
                                            placeholder="Enter Description"
                                        />
                                    </Col>
                                </Row>
                                <Row className="gy-4 mt-1">
                                    <Col lg="4">
                                        <FormSelect
                                            name="costCenter"
                                            label="Cost Center"
                                            options={costCenterOptions}
                                            required={true}
                                            placeholder="Select Cost Center"
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormDatePicker
                                            name='lastSubmissionDate'
                                            label="Last Submission Date"
                                            required={true}
                                            placeholder="Select Date"
                                            minDate={new Date()}
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="deadlineType"
                                            label="Time limit"
                                            options={[{ label: 'Soft', value: 'SOFT' }, { label: 'Hard', value: 'HARD' }]}
                                            required={true}
                                            placeholder="Select"
                                        />
                                    </Col>
                                </Row>
                                <Row className="gy-4 mt-1">
                                    <Col lg="4">
                                        <FormSelect
                                            name="siteId"
                                            label="Site Name"
                                            options={sitesOptions}
                                            required={true}
                                            placeholder="Select"
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="additionalCharges"
                                            label="Additional Charges?"
                                            options={[{ label: 'Fixed', value: 'Fixed' }, { label: 'Flexible', value: 'Flexible' }]}
                                            required={true}
                                            placeholder="Select"
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="autoRelease"
                                            label="Auto Release"
                                            defaultValue={{
                                                label: "Yes",
                                                value: "Y",
                                            }}
                                            options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
                                            required={true}
                                            placeholder="Select"
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-4">
                                    <Col lg="4">
                                        <FormSelect
                                            name="billingAddress"
                                            label="Billing Address"
                                            options={billingAddressOptions}
                                            required={true}
                                            placeholder="Select"
                                        //   onChange={onBillingAddressChange}
                                        />
                                        {/* {formState.selectedBillingAddress && (
                                            <Popover content={(
                                                <ul>
                                                    <li>{formState.selectedBillingAddress.addressLine1} {formState.selectedBillingAddress.addressLine2}</li>
                                                    <li>{formState.selectedBillingAddress.cityName}, {formState.selectedBillingAddress.stateName}, {formState.selectedBillingAddress.countryName} - {formState.selectedBillingAddress.pincode}</li>
                                                </ul>
                                            )} title="Selected Address">
                                                <a>View Selected Billing Address</a>
                                            </Popover>
                                        )} */}
                                    </Col>
                                    <Col lg="4">
                                        <FormSelect
                                            name="deliveryLevelType"
                                            label="Delivery Address at"
                                            options={[{ label: 'Order Level', value: 'ORDER_LEVEL' }, { label: 'Line Level', value: 'LINE_LEVEL' }]}
                                            required={true}
                                            placeholder="Select"
                                        //   onChange={handleDeliveryAddressTypeChange}
                                        />
                                    </Col>
                                    {OriginalData?.quotationHeader?.deliveryLevelType === "ORDER_LEVEL" && (
                                        <>
                                            <Col lg="4">
                                                <FormSelect
                                                    name="deliveryAddressId"
                                                    label="Delivery Address"
                                                    options={deliveryAddressOptions}
                                                    required={true}
                                                    placeholder="Select"
                                                // onChange={handleDeliveryAddressChange}
                                                />
                                                {/* {formState.selectedDeliveryAddress && (
                                                <Popover content={(
                                                    <ul>
                                                        <li>{formState.selectedDeliveryAddress.addressLine1} {formState.selectedDeliveryAddress.addressLine2}</li>
                                                        <li>{formState.selectedDeliveryAddress.cityName}, {formState.selectedDeliveryAddress.stateName}, {formState.selectedDeliveryAddress.countryName} - {formState.selectedDeliveryAddress.pincode}</li>
                                                    </ul>
                                                )} title="Selected Address">
                                                    <a>View Selected Delivery Address</a>
                                                </Popover>
                                            )} */}
                                            </Col>
                                            <Row className="mt-4">
                                                <Col lg="4">
                                                    <FormDatePicker
                                                        name='needByDate'
                                                        label="Need By Date"
                                                        required={true}
                                                        placeholder="Select Date"
                                                        minDate={new Date()}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Row>
                                <Row className="mt-4">
                                    <Col lg="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button
                                                    className="btn btn-primary btn-md"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Save
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setGeneralInformationEdit(false);
                                                        reset();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </form>
                        </FormProvider>
                    </>
                </ModalBody>
            </Modal>
            <Content>
                <Card className="card-bordered">
                    <div className="card-aside-wrap">
                        {isLoading == true ? (
                            <>
                                <Flex align="center" gap="middle" className="pageLoader">
                                    <Spin size="large" className="m-auto" />
                                </Flex>
                            </>
                        ) : (
                            <div className="card-inner card-inner-lg">
                                {sm && mobileView && (
                                    <div
                                        className="toggle-overlay"
                                        onClick={() => setSm(!sm)}
                                    ></div>
                                )}
                                <BlockHead size="lg">
                                    <BlockBetween>
                                        <BlockHeadContent>
                                            <BlockTitle tag="h4">
                                                <span>
                                                    RFQ - {OriginalData?.quotationHeader?.rfqId} |{" "}
                                                    {(statusMapper[rfqStatus] &&
                                                        statusMapper[rfqStatus]?.buyer?.title) ||
                                                        rfqStatus}{" "}
                                                </span>
                                            </BlockTitle>
                                        </BlockHeadContent>
                                        <div className="d-flex">
                                            {showSubmitBtm && (
                                                <div className="actions clearfix">
                                                    <ul
                                                        className="m-auto d-flex align-items-center justify-content-center p-0"
                                                        style={{
                                                            flexDirection: "row-reverse",
                                                        }}
                                                    >
                                                        <li className="pt-0 pb-0">
                                                            {disableSubmit ? (
                                                                <Button color="primary" type="button">
                                                                    <Spinner size="sm" color="light" />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setDisableSubmit(true);
                                                                        Swal.fire({
                                                                            title: "Are you sure?",
                                                                            text: "Do you want to submit the changes?",
                                                                            icon: "warning",
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: "#3085d6",
                                                                            cancelButtonColor: "#d33",
                                                                            confirmButtonText: "Yes, submit it!",
                                                                            cancelButtonText: "No, cancel",
                                                                        }).then(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                await SubmitChanges();
                                                                            }else{
                                                                                setDisableSubmit(false);
                                                                              }
                                                                        });
                                                                    }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            )}
                                                        </li>
                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                color="secondary"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to revert the changes?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, revert it!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            fetchDetails();
                                                                            Swal.fire(
                                                                                "Reverted!",
                                                                                "Your changes have been reverted.",
                                                                                "success"
                                                                            );
                                                                        }
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn-dim btn btn-secondary"
                                                            >
                                                                Revert
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}

                                            {!showSubmitBtm && (
                                                <div className="paddingLeft10">
                                                    <Button
                                                        className="toggle"
                                                        color="primary"
                                                        onClick={async () => {
                                                            navigate("/view-rfqs");
                                                        }}
                                                    >
                                                        <Icon name="arrow-left" />
                                                        <span>Back</span>
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                        <BlockHeadContent className="align-self-start d-lg-none">
                                            <Button
                                                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                                                    }`}
                                                onClick={() => setSm(!sm)}
                                            >
                                                <Icon name="menu-alt-r" />
                                            </Button>
                                        </BlockHeadContent>
                                    </BlockBetween>
                                </BlockHead>
                                <div className="content clearfix">
                                    <AntCollapse defaultActiveKey={["1"]}>
                                        <Panel header={<div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <span>General Details</span>
                                            <span
                                                className="cursor-pointer position-absolute"
                                                style={{
                                                    right: 20,
                                                    top: 10,
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                    setValue("quotationFor", OriginalData?.quotationHeader.quotationFor);
                                                    setValue("quotationDescription", OriginalData?.quotationHeader.quotationDescription);
                                                    setValue("costCenter", OriginalData?.quotationHeader?.costCenter);
                                                    setValue("lastSubmissionDate", dayjs(OriginalData?.quotationHeader?.lastSubmissionDate));
                                                    setValue("deadlineType", OriginalData?.quotationHeader?.deadlineType);
                                                    setValue("additionalCharges", OriginalData?.quotationHeader?.additionalCharges);
                                                    setValue("autoRelease", OriginalData?.quotationHeader?.autoRelease);
                                                    setValue("billingAddress", OriginalData?.quotationHeader?.billingAddressId);
                                                    setValue("deliveryLevelType", OriginalData?.quotationHeader?.deliveryLevelType);
                                                    setValue("deliveryAddressId", OriginalData?.quotationHeader?.deliveryAddressId);
                                                    setValue("siteId", OriginalData?.quotationHeader?.siteId);
                                                    setValue("needByDate", dayjs(OriginalData?.quotationHeader?.deliveryDate));
                                                    setGeneralInformationEdit(true); e.stopPropagation();
                                                }}
                                            >
                                                <Icon name="edit" />
                                            </span>
                                        </div>
                                        } key="1">
                                            <Descriptions title="" className="custom-description-title">
                                                <Descriptions.Item label="Quotation For">
                                                    {materialData?.quotationHeader?.quotationFor}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Description">
                                                    {materialData?.quotationHeader?.quotationDescription}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Cost Center">
                                                    {materialData?.quotationHeader?.costCenterName}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Last Submission Date">
                                                    <span className="badge-dim badge bg-primary rounded-pill">
                                                        <strong> {materialData?.quotationHeader?.lastSubmissionDate && dayjs(OriginalData?.quotationHeader?.lastSubmissionDate).format("DD-MMM-YYYY")}
                                                        </strong>
                                                    </span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Time Limit">
                                                    {materialData?.quotationHeader?.deadlineType}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Site Name">
                                                    {materialData?.quotationHeader?.siteName}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Approvals">
                                                    {<ApprovalTimelineContent />}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Additional Charges">
                                                    {" "}
                                                    {materialData?.quotationHeader?.additionalCharges}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Auto Release">
                                                    {materialData?.quotationHeader?.autoRelease === "Y" ? "Yes" : "No"}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Billing Address">
                                                    <Popover
                                                        content={billingAddressContent}
                                                        title={
                                                            <span style={{ color: "#2263b3" }}>Billing Address</span>
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#2263b3",
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {OriginalData?.quotationHeader?.billingAddressName?.deliveryLocationName}
                                                        </span>
                                                    </Popover>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Delivery Address">
                                                    {materialData?.quotationHeader?.deliveryLevelType === "LINE_LEVEL"
                                                        ? "Line Level"
                                                        : "Order Level"}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Documents Required From Supplier">
                                                    {materialData?.quotationHeader?.supplierDocsRequired === "Y" ? (
                                                        <Popover
                                                            content={requiredDocsContent}
                                                            title={
                                                                <span style={{ color: "#2263b3" }}>Required Documents</span>
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#2263b3",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Yes
                                                            </span>
                                                        </Popover>
                                                    ) : (
                                                        "No"
                                                    )}
                                                </Descriptions.Item>
                                                {materialData?.quotationHeader?.deliveryLevelType === "ORDER_LEVEL" && (
                                                    <>
                                                        <Descriptions.Item label="Delivery Address">
                                                            <Popover
                                                                content={orderDeliveryAddressContent}
                                                                title={
                                                                    <span style={{ color: "#2263b3" }}>Delivery Address</span>
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: "#2263b3",
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {
                                                                        materialData?.quotationHeader?.deliveryAddressName
                                                                            ?.deliveryLocationName
                                                                    }
                                                                </span>
                                                            </Popover>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Need By Date">
                                                            <span className="badge-dim badge bg-primary rounded-pill">
                                                                <strong>
                                                                    {dayjs(materialData?.quotationHeader?.deliveryDate).format("DD-MMM-YYYY")}
                                                                </strong>
                                                            </span>
                                                        </Descriptions.Item>
                                                    </>
                                                )}
                                            </Descriptions>
                                        </Panel>
                                        <Panel header="Materials" key="2">
                                            <Table
                                                columns={columns}
                                                dataSource={data.map((item: any, index: any) => ({
                                                    ...item,
                                                    key: index,
                                                }))}
                                                scroll={{ x: 1000 }}
                                                expandable={{
                                                    expandedRowRender: (record: any) => (
                                                        <div className="expandableBody">
                                                            {/* {record} */}
                                                            <div style={{ flex: 1, textAlign: "left" }}>
                                                                <p style={{ margin: 0 }}>{record.description}</p>
                                                                {record.attachment && (
                                                                    <div>
                                                                        <PaperClipOutlined /> &nbsp;
                                                                        {record?.file?.fileName ? (
                                                                            <a
                                                                                // href={URL.createObjectURL(
                                                                                //     record.file?.filePath
                                                                                // )}
                                                                                download={record.file?.fileName}
                                                                            >
                                                                                {record.file?.fileName}.
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                // href={record?.file[0]?.fileName}
                                                                                download={record?.file[0]?.fileName}
                                                                            >
                                                                                {record?.file[0]?.fileName}111
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {materialData?.quotationHeader?.deliveryLevelType ===
                                                                "LINE_LEVEL" &&
                                                                record.deliveryAddressName && (
                                                                    <div className="lineAddress" style={{ flex: 1 }}>
                                                                        <h6>Delivery Address</h6>
                                                                        <ul>
                                                                            <li>{record.deliveryAddressName.addressLine1}</li>
                                                                            <li>{record.deliveryAddressName.addressLine2}</li>
                                                                            <li>
                                                                                {record.deliveryAddressName.city},{" "}
                                                                                {record.deliveryAddressName.state.stateName},{" "}
                                                                                {record.deliveryAddressName.countryInfo.countryName} -{" "}
                                                                                {record.deliveryAddressName.pincode}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    ),
                                                }}
                                                bordered
                                                size="small"
                                                pagination={false}
                                                locale={{
                                                    emptyText: (
                                                        <span
                                                            style={{
                                                                height: "300px",
                                                                color: "#333",
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            Please click on "Add New Item" to add purchase
                                                            order lines.
                                                        </span>
                                                    ),
                                                }}
                                            />

                                            {data?.length > 0 && (
                                                <Row
                                                    style={{
                                                        margin: "20px",
                                                    }}
                                                >
                                                    <Col
                                                        className={`${!["Pending", "Draft", "REQUESTED"].includes(rfqStatus)
                                                            ? "justify-between"
                                                            : "justify-end"
                                                            }`}
                                                    >
                                                        {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                                                            <a
                                                                onClick={() => {
                                                                    setAddNewRowModel(true);
                                                                    setEditLineRow(null);
                                                                }}
                                                                href="#add"
                                                                className="addNewItem"
                                                            >
                                                                <Icon name="plus-round" />
                                                                <span>Add New Item</span>
                                                            </a>
                                                        )}
                                                    </Col>
                                                </Row>
                                            )}

                                        </Panel>
                                        <Panel header="Other Information" key="3">
                                            <Descriptions
                                                title="Other Information"
                                                className="custom-description-title"
                                            >
                                                <Descriptions.Item label="Instructions">
                                                    <span
                                                        style={{
                                                            color: "#2263b3",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => setInstructionsVisible(true)}
                                                    >
                                                        View
                                                    </span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Terms and Conditions">
                                                    <span
                                                        style={{
                                                            color: "#2263b3",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => setTermsVisible(true)}
                                                    >
                                                        View
                                                    </span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label="">
                                                    <div>
                                                        <strong className="">
                                                            Attachments &nbsp;
                                                            <PaperClipOutlined />
                                                        </strong>
                                                        <div>
                                                            {attachments?.map(
                                                                (attachment: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className="w-100 d-flex justify-content-between"
                                                                    >
                                                                        <div>
                                                                            <strong>{index + 1}.</strong>
                                                                            &nbsp;
                                                                            {attachment.fileName}
                                                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href={attachment.link}
                                                                                download={attachment.link}
                                                                                style={{
                                                                                    color: "black",
                                                                                    fontSize: "18px",
                                                                                }}
                                                                            >
                                                                                <DownloadOutlined />
                                                                            </a>
                                                                            &nbsp; &nbsp;
                                                                            {["Pending", "Draft", "REQUESTED"].includes(
                                                                                rfqStatus
                                                                            ) && (
                                                                                    <a
                                                                                        style={{
                                                                                            color: "red",
                                                                                            fontSize: "16px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setDeletedAttachments(
                                                                                                (prev: any) => [
                                                                                                    ...prev,
                                                                                                    { docId: attachment?.docId },
                                                                                                ]
                                                                                            );
                                                                                            let updatedAttachmentsList =
                                                                                                attachments?.filter(
                                                                                                    (item: any, i: number) =>
                                                                                                        i != index
                                                                                                );
                                                                                            setAttachments(
                                                                                                updatedAttachmentsList
                                                                                            );
                                                                                            setShowSubmitBtm(true);
                                                                                        }}
                                                                                    >
                                                                                        <DeleteOutlined />
                                                                                    </a>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                            {newAttachments?.map(
                                                                (attachment: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className="w-100 d-flex justify-content-between"
                                                                    >
                                                                        <div>
                                                                            <strong>
                                                                                {attachments?.length + index + 1}.
                                                                            </strong>
                                                                            &nbsp;
                                                                            {attachment?.name}
                                                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href={attachment.link}
                                                                                download={attachment.link}
                                                                                style={{
                                                                                    color: "black",
                                                                                    fontSize: "18px",
                                                                                }}
                                                                            >
                                                                                <DownloadOutlined />
                                                                            </a>
                                                                            &nbsp; &nbsp;
                                                                            <a
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontSize: "16px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setNewAttachments((prev: any) =>
                                                                                        prev.filter(
                                                                                            (item: any, i: number) =>
                                                                                                i != index
                                                                                        )
                                                                                    );
                                                                                    setShowSubmitBtm(true);
                                                                                }}
                                                                            >
                                                                                <DeleteOutlined />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                            {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                                                                <div className="mt-1">
                                                                    <Dragger
                                                                        beforeUpload={beforeUpload}
                                                                        fileList={[]}
                                                                        onChange={handleAttachmentChange}
                                                                    >
                                                                        <a
                                                                            onClick={() => { }}
                                                                            className="addNewItem"
                                                                        >
                                                                            <Icon name="plus-round" />
                                                                            <span>Add Attachment</span>
                                                                        </a>
                                                                    </Dragger>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="">
                                                    <div>
                                                        <strong className="">
                                                            Suppliers : &nbsp;
                                                        </strong>
                                                        <div>
                                                            <Row style={{ marginBottom: '15px', marginTop: "15px" }}>
                                                                <Col md="auto" style={{ whiteSpace: "nowrap" }}>
                                                                    {renderSupplierCheckboxes(suppliers.slice(0, 4))}
                                                                </Col>
                                                                <Col md="auto">
                                                                    {renderSupplierCheckboxes(suppliers.slice(4, 8))}
                                                                </Col>
                                                                <Col md="auto">
                                                                    {renderSupplierCheckboxes(suppliers.slice(8, 12))}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Descriptions.Item>
                                                {/* {suppliers &&
                                                    <Descriptions.Item label="Suppliers">
                                                        {suppliers.map((item) => (
                                                            <li>
                                                                {item.supplierName}
                                                            </li>
                                                        )
                                                        )}
                                                    </Descriptions.Item>
                                                } */}
                                            </Descriptions>
                                        </Panel>
                                    </AntCollapse>
                                </div>

                                {addNewRowModel && (
                                    <MaterialModal
                                        modal={addNewRowModel}
                                        closeModal={() => {
                                            setAddNewRowModel(false);
                                            setEditLineRow(null);
                                        }}
                                        status={rfqStatus}
                                        formData={{
                                            action: editLineRow == null ? "add" : "edit",
                                            index: editLineRow?.quotationLineId,
                                            item: editLineRow,
                                        }}
                                        append={(data: any) => {
                                            console.log("append");
                                            let newLineItems = materialData?.quotationLines;
                                            const _data = newLineItems.find((item: any) => {
                                                if (
                                                    item.materialId == data.materialId
                                                    // &&
                                                    // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                                    // dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                                                    // item.deliveryAddressId == data.deliveryAddressId
                                                )
                                                    return true;
                                            });

                                            if (_data) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: "Material Already Added",
                                                });
                                                return;
                                            }

                                            setMaterialData((pre: any) => {
                                                let newData = { ...pre };
                                                newData?.quotationLines?.push(data);
                                                console.log("addNEW::DATA", newData)
                                                return newData;
                                            });
                                            setData((pre: any) => {
                                                return [...pre, data];
                                            });
                                            setShowSubmitBtm(true);
                                        }}
                                        update={(id: any, data: any) => {
                                            console.log("update", data, id);
                                            let newLineItems = materialData?.quotationLines;
                                            console.log("newLineItems", newLineItems)
                                            const _data = newLineItems
                                                .filter((item: any) => item.quotationLineId != id)
                                                .find((item: any) => {
                                                    if (
                                                        item.materialId == data.materialId
                                                        // &&
                                                        // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                                        // dayjs(data.needByDate).format(
                                                        //     "DD-MMM-YYYY"
                                                        // ) &&
                                                        // item.deliveryAddressId == data.deliveryAddressId
                                                    )
                                                        return true;
                                                });
                                            console.log("_dataaa", _data)
                                            if (_data) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: " Material Already Added",
                                                });
                                                return;
                                            }

                                            newLineItems = newLineItems.map((item: any) => {
                                                if (item.quotationLineId == id) {
                                                    return data;
                                                }
                                                return item;
                                            });
                                            console.log("newLineItems", newLineItems)
                                            setMaterialData((pre: any) => {
                                                let newData = { ...pre, quotationLines: newLineItems };
                                                console.log("newData", newData)
                                                return newData;
                                            });
                                            setData(newLineItems);
                                            setShowSubmitBtm(true);
                                        }}
                                        needByDateType={OriginalData?.needByDateType}
                                    // deliveryAddressType={materialData?.deliveryAddressType}
                                    />
                                )}
                            </div>




                        )}
                    </div>
                </Card>
            </Content>
            <AntModal
                title={undefined}
                visible={instructionsVisible}
                footer={null}
                onCancel={() => {
                    setIsInstructionsChanged(false);
                    setInstructionsVisible(false);
                }}
                width="60%"
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <div className="d-flex">
                    <h5 className="m-3">Instructions </h5>
                    {!isInstructionsChanged &&
                        ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                            <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
                        )}
                </div>
                {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && isInstructionsChanged ? (
                    <ReactQuill
                        theme="snow"
                        value={newInstructions}
                        onChange={(data) => {
                            setNewInstructions(data);
                        }}
                        className="mb-3"
                    />
                ) : instructions ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: instructions || "",
                        }}
                        className="m-3"
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                        }}
                    >
                        <Empty description="No Instructions Available for this Purchase Order" />
                    </div>
                )}
                {isInstructionsChanged && (
                    <ul className="actions clearfix d-flex">
                        <li>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    setInstructionsVisible(false);
                                    setIsInstructionsChanged(true);
                                    setShowSubmitBtm(true);
                                }}
                            >
                                Submit
                            </Button>
                        </li>
                        <li className="m-3 mt-0">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setIsInstructionsChanged(false);
                                    setNewInstructions(instructions);
                                }}
                                className="btn-dim btn btn-secondary"
                            >
                                Cancel
                            </Button>
                        </li>
                    </ul>
                )}
            </AntModal>
            <AntModal
                title={undefined}
                visible={termsVisible}
                footer={null}
                onCancel={() => {
                    setIsTermsAndConditionsChanged(false);
                    setTermsVisible(false);
                }}
                width="60%"
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <div className="d-flex">
                    <h5 className="m-3">Terms and Conditions</h5>
                    {!isTermsAndConditionsChanged &&
                        ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                            <EditOutlined
                                onClick={() => setIsTermsAndConditionsChanged(true)}
                            />
                        )}
                </div>
                {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) &&
                    isTermsAndConditionsChanged ? (
                    <ReactQuill
                        theme="snow"
                        value={newTermsAndConditions}
                        onChange={(data) => {
                            setNewTermsAndConditions(data);
                        }}
                        className="mb-3"
                    />
                ) : termsAndConditions ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: termsAndConditions || "",
                        }}
                        className="m-3"
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                        }}
                    >
                        <Empty description="No Terms and Conditions Available for this Purchase Order" />
                    </div>
                )}

                {isTermsAndConditionsChanged && (
                    <ul className="actions clearfix d-flex">
                        <li>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    setTermsVisible(false);
                                    setIsTermsAndConditionsChanged(true);
                                    setShowSubmitBtm(true);
                                }}
                            >
                                Submit
                            </Button>
                        </li>
                        <li className="m-3 mt-0">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setIsTermsAndConditionsChanged(false);
                                    setNewTermsAndConditions(termsAndConditions);
                                }}
                                className="btn-dim btn btn-secondary"
                            >
                                Cancel
                            </Button>
                        </li>
                    </ul>
                )}
            </AntModal>
        </>)

};



export default ViewDetailsEdit;
