import React, { useState, useEffect, useRef } from 'react';
import { Select, Empty } from 'antd';
import * as echarts from 'echarts';
import { formatCurrency, formatCurrency2 } from '../../../utils/Utils';
import MaterialOverrunDrawer from './MaterialOverrunDrawer';
const { Option } = Select;

const categories = [
    { categoryID: 'cat001', categoryName: 'Steel' },
    { categoryID: 'cat002', categoryName: 'Cement' },
    { categoryID: 'cat003', categoryName: 'Electricals' },
    { categoryID: 'cat004', categoryName: 'HVAC' },
];

const budgetOverrunData = {
    siteID: 'SITE001',
    siteName: 'Site A',
    currency: 'INR',
    data: [
        {
            materialID: 'mat001',
            materialName: '10 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2400000,
            spent: 1500000,
            variation: -10, // Negative for down
            indicator: 'down',
        },
        {
            materialID: 'mat002',
            materialName: '12 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 3000000,
            spent: 3200000,
            variation: 6.67, // Positive for up
            indicator: 'up',
        },
        {
            materialID: 'mat003',
            materialName: '16 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2500000,
            spent: 2500000,
            variation: 0,
            indicator: 'median',
        },
        {
            materialID: 'mat004',
            materialName: '20 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 2800000,
            spent: 2600000,
            variation: -7.14,
            indicator: 'down',
        },
        {
            materialID: 'mat005',
            materialName: '25 MM TMT Bar',
            categoryID: 'cat001',
            categoryName: 'Steel',
            budgetAllocated: 3500000,
            spent: 4000000,
            variation: 14.29,
            indicator: 'up',
        },
        {
            materialID: 'mat006',
            materialName: 'OPC 43 Grade Cement',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 1500000,
            spent: 1400000,
            variation: -6.67,
            indicator: 'down',
        },
        {
            materialID: 'mat007',
            materialName: 'OPC 53 Grade Cement',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 2000000,
            spent: 2100000,
            variation: 5,
            indicator: 'up',
        },
        {
            materialID: 'mat008',
            materialName: 'PPC (Portland Pozzolana Cement)',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 1800000,
            spent: 1700000,
            variation: -5.56,
            indicator: 'down',
        },
        {
            materialID: 'mat009',
            materialName: 'PSC (Portland Slag Cement)',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 2200000,
            spent: 2200000,
            variation: 0,
            indicator: 'median',
        },
        {
            materialID: 'mat010',
            materialName: 'White Cement',
            categoryID: 'cat002',
            categoryName: 'Cement',
            budgetAllocated: 2500000,
            spent: 2400000,
            variation: -4,
            indicator: 'down',
        }
    ]
};

const MaterialWiseSpendAnalysis: React.FC = () => {
    const [selectedCategoryID, setSelectedCategoryID] = useState<string>(categories[0].categoryID);
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartInstanceRef = useRef<echarts.ECharts | null>(null);

    const disposeChart = () => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.dispose();
            chartInstanceRef.current = null;
        }
    };

    const initializeChart = (selectedData: any[]) => {
        if (chartContainerRef.current) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = echarts.init(chartContainerRef.current);
            }

            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any) => {
                        const budgetAllocated = formatCurrency(params[0].data.value, budgetOverrunData?.currency);
                        const amountSpent = formatCurrency(params[1].data.value, budgetOverrunData?.currency);
                        const variationColor = params[1].data.indicator === 'up' ? 'red' : 'green';
                        return `
                            <div>
                                <p><strong>${params[0].name}</strong></p>
                                <p>Budget Allocated: ${budgetAllocated}</p>
                                <p>Amount Spent: ${amountSpent}</p>
                                <p style="color: ${variationColor};">
                                    Variation: ${params[1].data.variation}% (${params[1].data.indicator === 'up' ? 'Over Spent' : 'Under Spent'})
                                </p>
                            </div>
                        `;
                    }
                },
                grid: {
                    left: '100px',
                    right: 0
                },
                xAxis: {
                    type: 'category',
                    data: selectedData.map(item => item.materialName),
                    name: 'Material',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 20,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: `Amount`,
                    axisLabel: {
                        formatter: (value: number) => formatCurrency2(value, budgetOverrunData?.currency),
                    },
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 60,
                    },
                },
                series: [
                    {
                        name: 'Budget Allocated',
                        type: 'bar',
                        data: selectedData.map(item => ({
                            value: item.budgetAllocated,
                            indicator: item.indicator,
                            variation: item.variation
                        })),
                        itemStyle: {
                            color: '#3BAEA3',
                        },
                        barWidth: '20%',

                    },
                    {
                        name: 'Amount Spent',
                        type: 'bar',
                        data: selectedData.map(item => ({
                            value: item.spent,
                            indicator: item.indicator,
                            variation: item.variation
                        })),
                        itemStyle: {
                            color: '#5470C6',
                        },
                        barWidth: '20%',
                    }
                ],
            };

            chartInstanceRef.current.setOption(option);
        }
    };


    const handleCategoryChange = (value: string) => {
        setSelectedCategoryID(value);
        const selectedData = budgetOverrunData.data.filter(data => data.categoryID === value);
        if (selectedData.length > 0) {
            initializeChart(selectedData);
        } else {
            disposeChart();
        }
    };

    useEffect(() => {
        const selectedData = budgetOverrunData.data.filter(data => data.categoryID === selectedCategoryID);
        if (selectedData.length > 0) {
            initializeChart(selectedData);
        } else {
            disposeChart();
        }

        return () => {
            disposeChart();
        };
    }, [selectedCategoryID]);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const openDrawer = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();  
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };
    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Material Wise Spend Analysis</h6>
                    <p>Insights on budget spends for different materials by category.</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by Category:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategoryID}
                        onChange={handleCategoryChange}
                    >
                        {categories.map(category => (
                            <Option key={category.categoryID} value={category.categoryID}>
                                {category.categoryName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            {budgetOverrunData.data.some(data => data.categoryID === selectedCategoryID) ? (
                <div ref={chartContainerRef} id="budget-overrun-chart" style={{ width: '100%', height: '300px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No budget details found for this category" />
                </div>
            )}
            <MaterialOverrunDrawer
                visible={drawerVisible}
                onClose={closeDrawer}
                data={budgetOverrunData.data}
                currency={budgetOverrunData.currency}
                categories={categories}
            />
            <div style={{marginTop: 20, fontSize: 16, textAlign: 'center' }}>
                <a href='' onClick={openDrawer}>Click here to view more details</a>
            </div>
        </div>
    );
};

export default MaterialWiseSpendAnalysis;
