import React, { useEffect, useState } from "react";
import { Badge, Button, Spinner } from "reactstrap";
import { useFormData } from "./FormDataContext"; // Import your context hook
import {
  Collapse as AntCollapse,
  Descriptions,
  Popover,
  Timeline,
  Table,
  List,
  Modal,
} from "antd";
import axios from "axios";
import { ApprovalData, Material } from "./types"; // Import your types
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import budgetApis from "../../../api/master/budget";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import rolesApi from "../../../api/master/roles";

const { Panel } = AntCollapse;

const BudgetPreview: React.FC<{ prev: () => void }> = (props) => {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { formData } = useFormData();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [roles, setRoles] = useState<any>([]);

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    console.log("formData.site", formData.budgetHeader.site)
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(moduleID, Number(formData.budgetHeader.site));
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Budget"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  const handleSubmit = async (data: any) => {
    setDisableSubmit(true);
    console.log("data", data, formData);
    let parsedData: any;
    if (formData.budgetHeader?.approvalsData) {
      parsedData = JSON.parse(formData.budgetHeader?.approvalsData);
    }
    const approvalDataArray = parsedData;
    const formattedApprovalData = approvalDataArray.map((item: any) => ({
      roleId: item.roleId,
      level: item.level,
      userId: item.userId,
    }));
    const submissionData = {
      header: {
        // ...formData.budgetHeader,
        // budgetId: nanoid(4),
        orgId: 1,
        status: "PENDING",
        budgetId: formData.budgetHeader?.budgetId || "",
        budgetName: formData.budgetHeader?.budgetName || "",
        startDate: dayjs(formData.budgetHeader?.startDate) || "",
        endDate: dayjs(formData.budgetHeader?.endDate) || "",
        description: formData.budgetHeader?.description || "",
        budgetFor: formData.budgetHeader?.budgetFor || "",
        site: parseInt(formData.budgetHeader?.site) || "",
        costCenter: formData.budgetHeader?.costCenter !== undefined ? parseInt(formData.budgetHeader?.costCenter) : null,
        lob: formData.budgetHeader?.lob || "",
        department: parseInt(formData.budgetHeader?.department) || "",
        docRequired: "Y",
        approvalRequired: formData.budgetHeader?.approvalRequired || "",
        instructions: formData?.instructions || "",
        terms: formData?.termsAndConditions || "",
      },
      lines:
        formData.materialsData?.map((material: any, index: number) => ({
          // budgetId: nanoid(4),
          // budgetLineId: index + 1,
          //   rfqLineId: `RFL${index + 1}`,
          budgetLineName: material.budgetLineName || "",
          budgetValue: parseInt(material.budgetValue) || "",
          startDate: dayjs(material.startDate) || "",
          endDate: dayjs(material.endDate) || "",
          bom: material.bom || "",
          allocation: material.allocation || null,
          lineDescription: material.lineDescription || "",
          currencyCode: "INR",
          bomLines: material.bomLines?.map((bomLine: any, index: number) => ({
            // bomId: index + 1,
            categoryId: parseInt(bomLine.categoryId) || "",
            categoryName: bomLine.categoryName || "",
            materialId: bomLine.materialId || "",
            materialName: bomLine.materialName || "",
            quantity: parseInt(bomLine.quantity) || "",
            allocated: parseInt(bomLine.allocated) || "",
          })),
        })) || [],
      // requiredDocs:
      //   formData.budgetHeader?.requiredDocs?.map((doc: any, index: number) => ({
      //     documentName: doc.documentName || "",
      //     mandatory: doc.mandatory || 0,
      //   })) || [],
      approvals: formattedApprovalData || [],
      // additionalData: {
      //   termsAndConditions: formData?.termsAndConditions || "",
      //   instructions: formData?.instructions || "",
      // },
    };

    // // Append attachments to docKeys

    console.log(JSON.stringify(submissionData));

    // Create FormData object
    const formDataToSend = new FormData();
    formDataToSend.append("header", JSON.stringify(submissionData.header));
    formDataToSend.append("lines", JSON.stringify(submissionData.lines));
    formDataToSend.append(
      "approvals",
      JSON.stringify(submissionData.approvals)
    );
    // formDataToSend.append(
    //   "additionalData",
    //   JSON.stringify(submissionData.additionalData)
    // );

    // Append files to FormData
    let fileIndex = 0;
    formData.docKeys.fileList.forEach((doc: any) => {
      if (doc.file) {
        formDataToSend.append(`files[${fileIndex}]`, doc.file, doc.fileName);
        fileIndex++;
      }
    });

    let _filesData: any = [];

    formData.docKeys?.fileList?.forEach((attachment: any, index: number) => {
      const file = new File([attachment], attachment.name, {
        type: attachment.type,
        lastModified: attachment.lastModified,
      });
      formDataToSend.append(`files`, file);
      _filesData.push({
        fileIndex: index,
        module: "Budget",
        moduleFileType: "Special Instructions",
        level: "HEADER_LEVEL",
      });
    });


    if (formData?.instructions) {
      _filesData.push({
        fileIndex: _filesData.length + 0,
        module: "Budget",
        moduleFileType: "instructions",
        level: "HEADER_LEVEL",
      });
      console.log("090999429", JSON.stringify(formData))
    }

    if (formData?.termsAndConditions) {
      _filesData.push({
        fileIndex: _filesData.length + 0,
        module: "Budget",
        moduleFileType: "termsAndConditions",
        level: "HEADER_LEVEL",
      });
      console.log("090999429", JSON.stringify(formData))
    }

    formDataToSend.append("docKeys", JSON.stringify(_filesData));


    if (formData?.instructions) {
      const instructionsFile = new Blob([formData?.instructions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      )
    }

    if (formData?.termsAndConditions) {
      const termsAndConditionsFile = new Blob([formData?.termsAndConditions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([termsAndConditionsFile], "termsAndConditions.html", {
          type: "text/html",
        })
      )
    }

    BudgetCreation(formDataToSend);

    // formData.attachments?.forEach((attachment: any) => {
    //   if (attachment.originFileObj) {
    //     formDataToSend.append(
    //       `files[${fileIndex}]`,
    //       attachment.originFileObj,
    //       attachment.name
    //     );
    //     fileIndex++;
    //   }
    // });

    // Log the formDataToSend for debugging
    // console.log("FormData to send:");
    // for (const [key, value] of Array.from(formDataToSend.entries())) {
    //   if (key.includes("files")) {
    //     console.log(key, value);
    //   } else {
    //     console.log(key, JSON.parse(value as string)); // parsing JSON string back to object for readability
    //   }
    // }

    // try {
    //   // Submit the form data to the API
    //   const response = await axios.post("/api/submit", formDataToSend, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //   console.log("Submission response:", response.data);
    // } catch (error) {
    //   console.error("Submission error:", error);
    // }
  };

  const BudgetCreation = async (data: any) => {
    try {
      const dataAPI: any = await budgetApis.budgetCreationMethod(data);
      console.log(dataAPI);
      const { status, message,response } = dataAPI;
      if (status) {
        Swal.fire("Created!", "Budget with Budget ID - " + response.budgetId + " has been created", "success");
        navigate(`/view-budget`);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
    setDisableSubmit(false);
  };

  // const approvalTimelineContent = formData?.budgetHeader?.quotationApprovals ? (
  //   <Timeline className="approval-timeline">
  //     {formData.budgetHeader.budgetApprovals.map(
  //       (approval: ApprovalData, index: number) => (
  //         <Timeline.Item key={index}>
  //           {approval.userName}
  //           <div className="designation">{approval.roleName}</div>
  //         </Timeline.Item>
  //       )
  //     )}
  //   </Timeline>
  // ) : null;

  const requiredDocsContent = formData?.budgetHeader?.requiredDocs ? (
    <Table
      dataSource={formData.budgetHeader.requiredDocs.map(
        (doc: any, index: number) => ({
          key: index + 1,
          sNo: index + 1,
          documentName: doc.documentName,
          mandatory: doc.mandatory ? "Yes" : "No",
        })
      )}
      columns={[
        {
          title: "S.No",
          dataIndex: "sNo",
          key: "sNo",
        },
        {
          title: "Document Name",
          dataIndex: "documentName",
          key: "documentName",
        },
        {
          title: "Mandatory to Upload",
          dataIndex: "mandatory",
          key: "mandatory",
        },
      ]}
      pagination={false}
      size="small"
      style={{ minWidth: "200px" }}
    />
  ) : null;

  useEffect(() => {
    console.log("HIIII", formData);
    fetchModuleConfig();
  }, []);

  const columns = [
    {
      title: "Budget Line",
      dataIndex: "budgetLineName",
      key: "budgetLineName",
      fixed: "left" as const,
      width: 120,
    },
    {
      title: "Budget Line Description",
      dataIndex: "lineDescription",
      key: "lineDescription",
      // fixed: 'left' as const,
      width: 200,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 150,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Bill Of Material (BOM)",
      dataIndex: "bom",
      key: "bom",
    },
    {
      title: "BOM Allocation",
      dataIndex: "allocation",
      key: "allocation",
    },
  ];

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(formData?.budgetHeader?.approvalsData);
    console.log("approvalsData", approvalsData)
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      formData?.budgetHeader?.approvalRequired == "N"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  return (
    <div className="content clearfix">
      <AntCollapse defaultActiveKey={["1"]}>
        <Panel header="General Details" key="1">
          <Descriptions title="" className="custom-description-title">
            <Descriptions.Item label="Budget Name">
              {formData?.budgetHeader?.budgetName}
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">
              {dayjs(formData?.budgetHeader?.startDate).format("DD-MMM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="End Date">
              {dayjs(formData?.budgetHeader?.endDate).format("DD-MMM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Budget Description">
              {formData?.budgetHeader?.description}
            </Descriptions.Item>
            <Descriptions.Item label="Budget for">
              {formData.budgetHeader?.budgetFor}
            </Descriptions.Item>
            <Descriptions.Item label="Site Name">
              {formData.budgetHeader?.siteName}
            </Descriptions.Item>
            <Descriptions.Item label="LOB">
              {formData.budgetHeader?.lob}
            </Descriptions.Item>
            <Descriptions.Item label="Cost Center">
              {formData.budgetHeader?.costCenterName}
            </Descriptions.Item>
            <Descriptions.Item label="Department">
              {formData.budgetHeader?.departmentName}
            </Descriptions.Item>
            <Descriptions.Item label="Approvals">
              {/* {formData.budgetHeader?.approvalRequired === "Y" ? (
                <Popover
                  content={approvalTimelineContent}
                  title={
                    <span style={{ color: "#2263b3" }}>Approval Hierarchy</span>
                  }
                >
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Yes
                  </span>
                </Popover>
              ) : (
                "No"
              )} */}
              {
                formData?.budgetHeader?.approvalsData && <ApprovalTimelineContent />
              }

            </Descriptions.Item>
            {/* <Descriptions.Item label="Additional Charges">
              {" "}
              {formData.budgetHeader?.additionalCharges}
            </Descriptions.Item>
            <Descriptions.Item label="Auto Release">
              {formData.budgetHeader?.autoRelease === "Y" ? "Yes" : "No"}
            </Descriptions.Item> */}
          </Descriptions>
        </Panel>
        <Panel header="Materials" key="2">
          <Table
            columns={columns}
            dataSource={formData.materialsData || []}
            scroll={{ x: "max-content" }}
            // expandable={{
            //   expandedRowRender: (record: Material) => (
            //     <div className="expandableBody">
            //       <div style={{ flex: 1, textAlign: "left" }}>
            //         <p style={{ margin: 0 }}>{record.description}</p>
            //         {record.fileName && record.file && (
            //           <div>
            //             <PaperClipOutlined /> &nbsp;
            //             <a
            //               href={URL.createObjectURL(record.file)}
            //               download={record.fileName}
            //             >
            //               {record.fileName}
            //             </a>
            //           </div>
            //         )}
            //       </div>
            //       {formData.quotationHeader?.deliveryLevelType ===
            //         "LINE_LEVEL" &&
            //         record.deliveryAddressDetails && (
            //           <div className="lineAddress" style={{ flex: 1 }}>
            //             <h6>Delivery Address</h6>
            //             <ul>
            //               <li>{record.deliveryAddressDetails.addressLine1}</li>
            //               <li>{record.deliveryAddressDetails.addressLine2}</li>
            //               <li>
            //                 {record.deliveryAddressDetails.cityName},{" "}
            //                 {record.deliveryAddressDetails.stateName},{" "}
            //                 {record.deliveryAddressDetails.countryName} -{" "}
            //                 {record.deliveryAddressDetails.pincode}
            //               </li>
            //             </ul>
            //           </div>
            //         )}
            //     </div>
            //   ),
            // }}
            bordered
            size="small"
            pagination={false}
            locale={{ emptyText: "No materials added" }}
          />
        </Panel>
        <Panel header="Other Information" key="3">
          <Descriptions
            title="Other Information"
            className="custom-description-title"
          >
            <Descriptions.Item label="Instructions">
              <span
                style={{
                  color: "#2263b3",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setInstructionsVisible(true)}
              >
                View Instructions
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Terms and Conditions">
              <span
                style={{
                  color: "#2263b3",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setTermsVisible(true)}
              >
                View Terms and Conditions
              </span>
            </Descriptions.Item>
            {formData.docKeys.fileList?.length > 0 && (
              <Descriptions.Item label="">
                <div>
                  <div className="">
                    Attachments &nbsp;
                    <PaperClipOutlined />
                  </div>
                  <div>
                    {formData.docKeys.fileList?.map(
                      (attachment: any, index: number) => (
                        <div
                          key={index}
                          className="w-100 d-flex justify-content-between"
                        >
                          <div>
                            <strong>{index + 1}.</strong>
                            &nbsp;
                            {attachment.name}
                            &nbsp; &nbsp; &nbsp; &nbsp;
                          </div>
                          <div>
                            <a
                              href={URL.createObjectURL(
                                attachment.originFileObj
                              )}
                              download={attachment.name}
                              style={{ color: "black", fontSize: "18px" }}
                            >
                              <DownloadOutlined />
                            </a>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Panel>
      </AntCollapse>
      <Modal
        title="Instructions"
        visible={instructionsVisible}
        footer={null}
        onCancel={() => setInstructionsVisible(false)}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: formData?.instructions || "",
          }}
        />
      </Modal>
      <Modal
        title="Terms and Conditions"
        visible={termsVisible}
        footer={null}
        onCancel={() => setTermsVisible(false)}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: formData?.termsAndConditions || "",
          }}
        />
      </Modal>
      <div className="actions clearfix">
        <ul>
          <li>

            {disableSubmit ? (
              <Button color="primary" type="button">
                <Spinner size="sm" color="light" />
              </Button>
            ) : (
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </li>
          <li>
            <Button
              color="secondary"
              onClick={props.prev}
              className="btn-dim btn btn-secondary"
            >
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(BudgetPreview);
