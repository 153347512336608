import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Collapse,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  TooltipComponent,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import sites from "../../api/master/sites";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import type { TableProps } from "antd";
import {
  Form,
  TableColumnsType,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  Divider,
  Space,
  Timeline,
} from "antd";
import { nanoid } from "nanoid";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import misc from "../../api/misc/dropDowns";
import adminOrgApis from "../../api/master/adminOrg";
import EditPoModal from "./EditPoModal";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import Swal from "sweetalert2";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { StepsProps } from "antd";
import { Popover, Steps } from "antd";
import materialsApis from "../../api/master/materials";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";

interface LineItem {
  lineNo: number;
  uuid: string;
  orderNumber: number;
  needByDate: string | null;
  materialId: number;
  hsnCode: number;
  categoryId: number;
  deliveryAddressId: number | null;
  rejectedReason: string;
  description: string;
  quantity: string;
  uomId: number;
  changeRequestQuantity: string;
  quantityReturned: string;
  quantityDelivered: string;
  unitPrice: string;
  basicAmount: string;
  discountPercentage: string;
  discount: string;
  netAmount: string;
  sgstPercentage: string;
  cgstPercentage: string;
  igstPercentage: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  status: string;
  deliveryStatus: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
}

interface EventChain {
  id: number;
  orderNumber: number;
  orgId: number;
  eventType: string;
  eventName: string;
  error: string | null;
  eventDate: string;
  createdBy: number;
}

interface Item {
  orderNumber: number;
  referenceId: string;
  mode: string;
  version: number;
  orderDate: string;
  needByDateType: string;
  needByDate: string | null;
  orgId: number;
  siteId: number;
  supplierId: number;
  costCenterId: number;
  description: string;
  ledgerId: number;
  departmentId: number;
  budgetId: number;
  paymentTerms: string;
  advancePercentage: number;
  creditDays: number;
  paymentTermsDescription: string | null;
  inwardRequired: number;
  autoRelease: number;
  autoPoDoc: number;
  billingAddressId: number;
  deliveryAddressType: string;
  deliveryAddressId: string | null;
  currencyId: number;
  basicAmount: string;
  discount: string;
  netAmount: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
  rejectedReason: string;
  approvalStatus: string;
  status: string;
  deliveryStatus: string;
  lineItems: LineItem[];
  eventChains: EventChain[];
  supplierName: string;
  siteName: string;
  costCenterName: string;
  createdUserName: string;
  createdUserId: number;
  currencyCode: string;
  currency: string;
  currencySymbol: string;
}


interface SelectOptions {
  label: string;
  value: string;
}
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}
const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];
const ViewInvoiceOrder: FC<any> = ({ PoData }) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [tab, setTab] = useState("1");
  const [mobileView, setMobileView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState("1");
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [materialData, setMaterialData] = useState<any>({});
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
    SelectOptions[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );
  const [budgetOptions, setBudgetOptions] = useState<SelectOptions[]>([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [allCurrencyData, setAllCurrencyData] = useState<CurrencyData[]>([]);
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any[]>();
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const fetchOrderDetails = async () => {
    try {
      const res = await purchaseOrderApis.fetchOrder(PoData.orderNumber);
      const { response, status, message } = res;
      if (status) {
        console.log(JSON.stringify(response.lineItems));
        setMaterialData(response);
        setData(response.lineItems);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchSupplier = async () => {
    try {
      const res: any = await purchaseOrderCreation.fetchBuyerLinkedSupplierListMethod();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data.map((item: any) => ({
          label: item?.sellerOrg?.name,
          value: item?.sellerOrg?.orgId?.toString(),
        })); 
        setSupplierOptions(supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const res = await sites.fetchSites();
      const { data, status, message } = res;
      if (status) {
        let sitesOptions = data.map((item: any) => {
          return { label: item.name, value: item.siteID.toString() };
        });
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGeneralLedger = async () => {
    try {
      const { data, status, message } = await GeneralLedgerApis.fetchLedger();
      if (status) {
        let generalLedgerOptions = data.map((item: any) => {
          return { label: item.ledgerName, value: item.ledgerID.toString() };
        });
        setGeneralLedgerOptions(generalLedgerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDepartment = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => {
          return { label: item.deptName, value: item.deptID.toString() };
        });
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCategorys();
    fetchMaterials();
    fetchCurrencyData();
    fetchUOMOPtions();
    fetchDeliveryAddress();
    fetchSupplier();
    fetchCostCenter();
    fetchSites();
    fetchGeneralLedger();
    fetchDepartment();
  }, []);

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any = "₹"
  ) => {
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const [columns, setColumns] = useState<TableColumnsType<Item>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Materail Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text) => FormatDate(text),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record: any) => `${text} ${record?.uomId}`,
    },
    {
      title: "Rate",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text) => FormatCurrency(text, "", ""),
    },
    {
      title: "Amt",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text) => (
        <p className="text-right">{FormatCurrency(text, "", "")}</p>
      ),
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text) => `${Number(text).toFixed(1)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text) => (
            <p className="text-right">{FormatCurrency(text, "", "")}</p>
          ),
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text) => `${Number(text).toFixed(1)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text) => (
            <p className="text-right">{FormatCurrency(text, "", "")}</p>
          ),
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text) => `${Number(text).toFixed(1)}%`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text) => (
            <p className="text-right">{FormatCurrency(text, "", "")}</p>
          ),
        },
      ],
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record: any, index) =>
        ["Rejected", "Rejected by Supplier"].includes(PoData?.status) ? (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setEditLineRow(record);
              }}
            >
              <Icon name="focus" />
            </Button>
          </>
        ) : (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setEditLineRow(record);
              }}
            >
              <Icon name="edit" />
            </Button>
          </>
        ),
    },
  ]);

  const addNewRow=()=>{
    alert("addNewRow")
  }

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
      // fetchMaterialDetails(editLineRow?.materialCategory);
    }
  }, [editLineRow]);
  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status } = res;
      if (status) {
        let allCurrencyData = data.map((item: any) => {
          return {
            description: item.description,
            currency_code: item.currency_code,
            currency_symbol: item.currency_symbol,
            currency_format: item.currency_format,
            example: item.example,
          };
        });
        // setCurrencyOptions(
        //   data.map((item: any) => {
        //     return {
        //       label: item.description,
        //       value: item.currency_code,
        //     };
        //   })
        // );
        setAllCurrencyData(allCurrencyData);
        // setCurrency(data.find((item: any) => item.currency_code == "INR"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    // try {
    //   const { data, status, message } =
    //     await deliveryAddressApis.fetchDeliveryAddress();

    //   if (status) {
    //     let deliveryAddressOptions = data.map((item: any) => {
    //       return {
    //         label: item.deliveryLocationName,
    //         value: item.id.toString(),
    //       };
    //     });
    //     setDeliveryAddressOptions(deliveryAddressOptions);
    //   } else {
    //     console.log(message);
    //   }
    // } catch (error) {}
  };

  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

   const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
    const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const customDot: StepsProps["progressDot"] = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
    >
      {dot}
    </Popover>
  );
  return (
    <>
      <Head title="Purchase Order - Track" />
      <Content>
        <Block>
          <Row className="mt-1">
            <div className="accordion accordian-s3">
              <div className="accordion-item">
                <div
                  className="accordion-head collapsed"
                  onClick={() => setIsOpen("1")}
                >
                  <h6 className="title">
                    <Icon name="bookmark" />
                    <span>General Information</span>
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse
                  className="accordion-body "
                  isOpen={isOpen === "1" ? true : false}
                >
                  <div className="accordion-inner">
                    <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs">
                      <li className="nav-item" onClick={() => setTab("1")}>
                        <a
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "1" ? " active" : ""}`}
                          href="#year"
                        >
                          General Info
                        </a>
                      </li>
                      <li className="nav-item" onClick={() => setTab("2")}>
                        <a
                          href="#overview"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "2" ? " active" : ""}`}
                        >
                          Delivery Info
                        </a>
                      </li>
                      <li className="nav-item" onClick={() => setTab("3")}>
                        <a
                          href="#approval"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "3" ? " active" : ""}`}
                        >
                          Approval Info
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-0">
                      {tab === "1" && (
                        <div className={`tab-pane${tab === "1" && " active"}`}>
                          <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                            <div className="w-30 border-end ">
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">Site</div>
                                <div className="data-value w-70 ">
                                  {materialData?.siteName}
                                </div>
                              </div>
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">Supplier</div>
                                <div className="data-value w-70 ">
                                  {materialData?.supplierName}
                                </div>
                              </div>
                              <div className="data-col align-center pt-1">
                                <div className="data-label w-30">
                                  Description
                                </div>
                                <div className="data-value w-70">
                                  {materialData?.description || "--"}
                                </div>
                              </div>
                            </div>
                            <div className="w-30 border-end paddingLeft10 ">
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">Budget</div>
                                <div className="data-value w-70 ">
                                  {materialData?.budgetName}
                                </div>
                              </div>

                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Payment Terms
                                </div>
                                <div className="data-value w-70 ">
                                  <Badge color="outline-success">
                                    {materialData?.paymentTerms || "--"}
                                  </Badge>
                                </div>
                              </div>
                              {/* {previousFormDate?.advancePercentage && ( */}
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">Advance</div>
                                <div className="data-value w-70 ">
                                  <span className="change up text-danger">
                                    {materialData?.advancePercentage || "--"}%
                                    {/* <Icon name="arrow-long-up"></Icon> */}
                                  </span>
                                </div>
                              </div>
                              {/* )} */}
                              {/* {previousFormDate?.creditDays && ( */}
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Credit Days
                                </div>
                                <div className="data-value w-70 ">
                                  <Badge color="outline-primary">
                                    {materialData?.creditDays || "--"} days
                                  </Badge>
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                            <div className="w-30 paddingLeft10">
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Cost Center
                                </div>
                                <div className="data-value w-70 ">
                                  {materialData?.costCenterName}
                                </div>
                              </div>

                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">Ledger</div>
                                <div className="data-value w-70 ">
                                  {materialData?.ledgerName}
                                </div>
                              </div>

                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Department
                                </div>
                                <div className="data-value w-70 ">
                                  {materialData?.departmentName}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      )}
                      {tab === "2" && (
                        <div className={`tab-pane${tab === "2" && " active"}`}>
                          <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                            <div className="w-25 border-end">
                              <div className="data-col align-center pt-1">
                                <div className="data-label w-50">
                                  Inward Required
                                </div>
                                <div className="data-value w-50">
                                  {materialData?.inwardRequired ? (
                                    <Badge color="outline-success">Yes</Badge>
                                  ) : (
                                    <Badge color="outline-danger">No</Badge>
                                  )}
                                </div>
                              </div>
                              <div className="data-col align-center pt-1">
                                <div className="data-label w-50">
                                  Line Level Delivery
                                </div>
                                <div className="data-value w-50">
                                  {materialData?.needByDate != "Order Level" ? (
                                    <Badge color="outline-success">Yes</Badge>
                                  ) : (
                                    <Badge color="outline-danger">No</Badge>
                                  )}
                                </div>
                              </div>

                              <div className="data-col align-center pt-1">
                                <div className="data-label w-50">
                                  Delivery Type
                                </div>
                                <div className="data-value w-50">
                                  {materialData?.deliveryAddressType || "--"}
                                </div>
                              </div>

                              <div className="data-col pt-1">
                                <div className="data-label w-50">
                                  Delivery Date
                                </div>
                                <div className="data-value w-50">
                                  <Badge color="outline-primary">
                                    {FormatDate(materialData?.deliveryDate)}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className="w-25 border-end paddingLeft10">
                              <div className=" pt-1">
                                <div className="data-label w-90 mb-1">
                                  Billing Address
                                </div>
                                <div className="data-value w-90">
                                  {materialData?.billingAddress}
                                </div>
                              </div>
                            </div>
                            <div className="w-25 border-end paddingLeft10 ">
                              <div className=" pt-1">
                                <div className="data-label w-9 mb-1">
                                  Delivery Address
                                </div>
                                <div className="data-value w-90">
                                  {materialData?.deliveryAddress}
                                </div>
                              </div>
                            </div>
                            <div className="w-25 paddingLeft10">
                              <div className="  pt-1">
                                <div className="data-label w-90 mb-1">
                                  Supplier Info
                                </div>
                                <div className="data-value w-90">
                                  {materialData?.supplierName}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      )}

                      {tab === "3" && (
                        <div className={`tab-pane${tab === "3" && " active"}`}>
                          <Col className=" m-auto mt-3 p-3">
                            <h5>Approvals</h5>
                            <div className="mb-3">
                              Auto Approval :{" "}
                              {materialData?.autoApproval ? (
                                <Badge color="outline-success">Yes</Badge>
                              ) : (
                                <Badge color="outline-danger">No</Badge>
                              )}
                            </div>
                            {materialData?.multiLevelApproval && (
                              <div
                                className="m-auto "
                                style={{
                                  width: "fit-content",
                                }}
                              >
                                <Steps
                                  current={5}
                                  progressDot={customDot}
                                  items={Array.from(
                                    {
                                      length: parseInt(
                                        materialData.approvalLevels
                                      ),
                                    },
                                    (_, i) => `level${i + 1}`
                                  ).map((level, index) => ({
                                    title: "Pending",
                                    description: `User ${
                                      materialData[level]
                                    } (Level ${index + 1})`,
                                  }))}
                                />
                              </div>
                            )}
                          </Col>
                        </div>
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className="accordion-head collapsed"
                  onClick={() => setIsOpen("2")}
                >
                  <h6 className="title">
                    <Icon name="bookmark" />
                    <span>Material Information</span>
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse
                  className="accordion-body "
                  isOpen={isOpen === "2" ? true : false}
                >
                  <div className="accordion-inner" style={{ padding: "10px" }}>
                    <div
                      style={{
                        width: screenSize - 420 + "px",
                        margin: "15px auto",
                        minHeight: "200px",
                      }}
                    >
                      <Table
                        className="customTable"
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 1000 }}
                        // expandable={{
                        //   expandedRowRender: (record) => (
                        //     <p style={{ margin: 0, textAlign: "left" }}>
                        //       {record.description}
                        //     </p>
                        //   ),
                        // }}
                        // locale={{
                        //   emptyText: (
                        //     <span
                        //       style={{
                        //         height: "300px",
                        //         color: "#333",
                        //         fontSize: "16px",
                        //       }}
                        //     >
                        //       Please click on "Add New Item" to add purchase
                        //       order lines.
                        //     </span>
                        //   ),
                        // }}
                        bordered
                        size="small"
                        pagination={false}
                      />
                    </div>
                    <Row
                      style={{
                        margin: "20px",
                      }}
                    >
                      <Col className="justify-between">
                        <a onClick={addNewRow} className="addNewItem">
                          <Icon name="plus-c" />
                          <span>Add New Item</span>
                        </a>
                        <div className="w-25">
                          <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                              <strong>Taxable Amount</strong>
                            </div>
                            <span className="r_align">
                              {FormatCurrency(
                                data?.reduce(
                                  (a, b) => a + Number(b.basicAmount),
                                  0
                                ),
                                currency?.currency_format,
                                currency?.currency_symbol
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                              <strong>CGST</strong>
                            </div>
                            <span className="r_align">
                              {FormatCurrency(
                                data?.reduce(
                                  (a, b) => a + Number(b.cgst),
                                  0
                                ),
                                currency?.currency_format,
                                currency?.currency_symbol
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                              <strong>SGST</strong>
                            </div>
                            <span className="r_align">
                              {FormatCurrency(
                                data?.reduce(
                                  (a, b) => a + Number(b.sgst),
                                  0
                                ),
                                currency?.currency_format,
                                currency?.currency_symbol
                              )}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                              <strong>IGST</strong>
                            </div>
                            <span className="r_align">
                              {FormatCurrency(
                                data?.reduce(
                                  (a, b) => a + Number(b.igst),
                                  0
                                ),
                                currency?.currency_format,
                                currency?.currency_symbol
                              )}
                            </span>
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                          >
                            <div className="w-50 justify-end">
                              <strong>Total</strong>
                            </div>
                            <strong className="r_align">
                              {FormatCurrency(
                                data?.reduce(
                                  (a, b) => a + Number(b.grossTotal),
                                  0
                                ),
                                currency?.currency_format,
                                currency?.currency_symbol
                              )}
                            </strong>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className="accordion-head collapsed"
                  onClick={() => setIsOpen("3")}
                >
                  <h6 className="title">
                    <Icon name="bookmark" />
                    <span>Additional Information</span>
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse
                  className="accordion-body"
                  isOpen={isOpen === "3" ? true : false}
                >
                  <div className="accordion-inner"></div>
                </Collapse>
              </div>
            </div>
          </Row>
        </Block>
        <>
          <Modal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(false)}
            className="modal-dialog-centered"
            size="lg"
            backdrop="static"
          >
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setIsModalOpen(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>

            <ModalBody>
              <div className="text-center">
                <h2 className="title">Are you sure?</h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Commodi autem fugit, quas voluptatibus rerum ratione eligendi
                  iure? Delectus eum debitis beatae inventore earum veritatis
                  fugiat error, ea soluta dolore quisquam.
                </p>
              </div>
            </ModalBody>
          </Modal>
        </>
        <>
          {addNewRowModel && (
            <EditPoModal
              modal={addNewRowModel}
              closeModal={() => {
                setAddNewRowModel(false);
                setEditLineRow(null);
              }}
              onSubmit={(data: any) => console.log(data)}
              status={
                ["Rejected", "Rejected by Supplier"].includes(PoData?.status)
                  ? "view"
                  : "edit"
              }
              formData={editLineRow}
              categoryOptions={categoryOptions}
              materialOptions={materialCategoryOptions}
              uomOptions={uomOptions}
              TAX_SLAP_DROPDOWN={TAX_SLAP_DROPDOWN}
              deliveryAddressOptions={[]}
            />
          )}
        </>
        <>
          <Row className="gy-4 mt-1">
            <Col lg="3" className="d-flex align-items-center justify-around">
              <Button
                className="btn-dim"
                outline
                color="success"
                type="button"
                onClick={() => {
                  Swal.fire("success", "Order has been approved", "success");
                }}
              >
                Approved
              </Button>
              <Button
                className="btn-dim"
                outline
                color="danger"
                type="submit"
                onClick={() => {
                  Swal.fire("success", "Order has been rejected", "success");
                }}
              >
                Reject
              </Button>
            </Col>
          </Row>
        </>
      </Content>
    </>
  );
};

export default ViewInvoiceOrder;
